import { useState } from 'react';
import SinglePoint from './SinglePoint';

const SinglePointWrapper = props => {
  const [version, setVersion] = useState(0);
  const forceRerender = () => setVersion(version + 1);
  return (
    <div className="single-point-wrapper" key={version}>
      <SinglePoint forceRerender={forceRerender} {...props} />
    </div>
  );
};

export default SinglePointWrapper;
