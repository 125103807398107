import React, { ReactNode, useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  FaSortAmountDownAlt,
  FaSortAmountDown,
  FaArrowDown,
  FaArrowUp,
  FaEllipsisV,
} from 'react-icons/fa';

import './AdvancedTableWrapper.scss';

type IAdvancedTableWrapper = {
  data: any[];
  config: {
    pagination?: {
      totalRecords: number;
      currentPage: number;
      pageSize: number;
      totalPages: number;
      setPage: (page: number) => void;
    };
    onSort?: (sort: string[]) => void;
    onClick?: (id: string) => void;
    columns: {
      id: string;
      label: string;
      onRender?: (row: any) => JSX.Element;
    }[];
    more?: Array<{ label: string; onClick: () => void }>;
  };
};

const AdvancedTableWrapper = ({ data, config }: IAdvancedTableWrapper) => {
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (sortField.length && onSort) onSort([`${sortField}:${sortOrder}`]);
  }, [sortField, sortOrder]);
  if (!data) {
    return <div>Ładowanie danych</div>;
  }

  const { columns, onSort, pagination, onClick, more } = config;

  const onSortHandler = (field: string) => {
    if (field === sortField) {
      const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newOrder);
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortIcon = sortOrder === 'asc' ? <FaArrowUp /> : <FaArrowDown />;

  return (
    <div className="AdvancedTableWrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ alignItems: 'center' }}>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align="left"
                  onClick={() => onSort && onSortHandler(column.id)}
                >
                  {sortField === column.id ? (
                    <b className="table-cell">
                      {sortIcon} {column.label}
                    </b>
                  ) : (
                    <p className="table-cell">{column.label}</p>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} onClick={() => onClick && onClick(row.id)}>
                {columns.map(column => (
                  <TableCell align="left" key={column.id}>
                    {column.onRender ? column.onRender(row) : row[column.id]}
                  </TableCell>
                ))}
                {more && (
                  <TableCell align="right" className="more">
                    <IconButton
                      onClick={event => setAnchorEl(event.currentTarget)}
                    >
                      <FaEllipsisV />
                    </IconButton>
                    <div className="list-menu">
                      {more.map(item => (
                        <div>test</div>
                      ))}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination && (
        <Pagination
          count={pagination.totalPages}
          page={pagination.currentPage}
          onChange={(event, page) => pagination.setPage(page)}
        />
      )}
    </div>
  );
};

export default AdvancedTableWrapper;
