import React from 'react';
import { Field } from 'formik';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import './Dropdown.scss';

const DropdownInput = ({
  id,
  name,
  label,
  placeholder,
  options,
  onSelect,
  indexValue,
  className,
  disabled,
}) => {
  return (
    <div className={`dropdown-wrapper ${className}`}>
      <label>{label || placeholder}</label>
      <Field name={name || id}>
        {({ field, form }) => (
          <FormControl
            fullWidth
            error={form.touched[field.name] && !!form.errors[field.name]}
          >
            <Select
              {...field}
              id={id}
              value={field.value || ''}
              onChange={event => {
                const { value } = event.target;
                field.onChange({ target: { name: field.name, value } });
                onSelect && onSelect({ name, value, indexValue });
              }}
              disabled={disabled}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default DropdownInput;
