import { Field, useFormikContext } from 'formik';
import './CheckboxInput.scss';

const CheckboxInput = ({ id, name, placeholder, className }) => {
  const formik = useFormikContext();
  const currentValue = formik.values[id];

  return (
    <div className={`checkbox-wrapper ${className}`}>
      <label className={`${currentValue ? 'true' : 'false'}`}>
        <Field name={name || id}>
          {({ field }) => (
            <input
              type="checkbox"
              id={id}
              {...field}
              checked={field.value}
              onChange={event => {
                const { checked } = event.target;
                formik.setFieldValue(field.name, checked);
              }}
            />
          )}
        </Field>
        {placeholder}
      </label>
      {formik.errors[id] && (
        <span className="validation-error-description">
          {formik.errors[id]}
        </span>
      )}
    </div>
  );
};

export default CheckboxInput;
