import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import Popup from 'modules/Popup/Popup';
import { SuccessButton, WarningButton } from 'components/CustomeMaterial';
import FetchListPickerInput from 'modules/FetchListPickerInput';

const AddSinglePoint = ({ onSelect, onClose }) => {
  return (
    <Popup title="Dodaj punkt" onClose={onClose}>
      <FormikWrapper initialValues={{ locationId: null }} onSubmit={onSelect}>
        <div className="point-form">
          <FetchListPickerInput name="location" />
        </div>
        <div className="btn-container">
          <SuccessButton type="submit">Dodaj</SuccessButton>
          <WarningButton onClick={onClose}>Anuluj</WarningButton>
        </div>
      </FormikWrapper>
    </Popup>
  );
};

export default AddSinglePoint;
