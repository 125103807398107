import { useState } from 'react';
import FormikWrapper from 'components/FormikWrapper';
import {
  PrimaryButton,
  TextInput,
  WarningButton,
} from 'components/CustomeMaterial';

const AdvancedFilter = ({ config, setFilters }) => {
  const [formKey, setFormKey] = useState(0);

  const initialValues = {};
  config.forEach(filter => (initialValues[filter.id] = filter.initialValue));

  const getInputs = config
    .filter(input => !input.isHidden)
    .map((input, index) => {
      return (
        <TextInput key={index} id={input.id} placeholder={input.placeholder} />
      );
    });
  return (
    <div className="AdvancedFilter" key={formKey}>
      <FormikWrapper initialValues={initialValues} onSubmit={setFilters}>
        <div className="form-container">{getInputs}</div>
        <div className="button-container">
          <PrimaryButton type="submit">Filtruj</PrimaryButton>
          <WarningButton
            onClick={() => {
              setFilters({});
              setFormKey(prev => prev + 1);
            }}
            type="reset"
          >
            Resetuj
          </WarningButton>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default AdvancedFilter;
