import { ConfigContext } from '../providers/ConfigProvider';

import { useContext } from 'react';

const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within an ConfigProvider');
  }

  return context;
};

export default useConfig;
