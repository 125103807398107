import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pl';

import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';
import Connector from 'connector';
import { useParams } from 'react-router-dom';
import { replaceDocumentIdentifier } from 'helpers/replaceDocumentIdentifier';

import Popup from 'modules/Popup/Popup';
import AddEditWasteCardPopup from './AddEditWasteCardPopup/AddEditWasteCardPopup';
import useConfig from 'hooks/useConfig';

import './WasteCards.scss';
import { SuccessButton } from 'components/CustomeMaterial';

const WasteCards = () => {
  const { UNITS, SELF_INVOICE_FORMATS, WASTE_DOCUMENT_NUMBER_FORMATS } =
    useConfig();

  const { id: clientId } = useParams();
  const navigate = useNavigate();
  const [wasteCards, setWasteCards] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOpenWasteCardPopup, setIsOpenWasteCardPopup] = useState(false);
  const [filterDate, setFilterDate] = useState(moment().date(15).toISOString());

  const fetchData = async () => {
    const response = await Connector.getWasteCardsByClientIdAndDate({
      id: clientId,
      date: filterDate,
    });
    setWasteCards(response.data.data);
  };

  useEffect(() => {
    fetchData();
  }, [filterDate]);

  const getInvoiceCell = row => {
    if (row.noInvoice) {
      return <div className="no-invoice">Bez faktury</div>;
    }

    if (!row.noInvoice && !row.invoice) {
      return <div className="wait-on-invoice">Oczekuje na zafakturowanie</div>;
    }

    const { createDate, number, numberFormat } = row.invoice;
    const documentIdentifier = replaceDocumentIdentifier({
      date: createDate,
      number: number,
      format: SELF_INVOICE_FORMATS[numberFormat],
    });

    return <div className="invoice-cell">{documentIdentifier}</div>;
  };

  const config = {
    columns: [
      {
        id: 'documentNumber',
        label: 'Numer dokumentu',
        onRender: row => {
          const documentIdentifier = replaceDocumentIdentifier({
            date: row.transferDate,
            number: row.wasteNumber,
            format: WASTE_DOCUMENT_NUMBER_FORMATS[Number(row.driverId)],
          });
          return documentIdentifier;
        },
      },
      {
        id: 'transferDate',
        label: 'Data transferu',
        onRender: row => moment(row.transferDate).format('DD.MM.YYYY'),
      },
      {
        id: 'localName',
        label: 'Nazwa restauracji',
        onRender: row => row.location.localName,
      },
      {
        id: 'clientName',
        label: 'Nazwa klienta',
        onRender: row => row.client.companyName,
      },
      {
        id: 'driverName',
        label: 'Kierowca',
        onRender: row => row.driver.login,
      },
      {
        id: 'count',
        label: 'Ilość',
        onRender: row => (
          <span>
            {row.count} {UNITS[5]}
          </span>
        ),
      },
      {
        id: 'invoiceNumber',
        label: 'Numer faktury',
        onRender: row => getInvoiceCell(row),
      },
      {
        id: 'actions',
        label: 'Akcje',
        onRender: row => (
          <div className="actions">
            {!row.noInvoice && !row.invoice && (
              <input
                type="checkbox"
                checked={selected.includes(row.id)}
                onChange={() => {
                  if (selected.includes(row.id)) {
                    setSelected(selected.filter(id => id !== row.id));
                  } else {
                    setSelected([...selected, row.id]);
                  }
                }}
              />
            )}
          </div>
        ),
      },
      {
        id: 'go',
        label: 'Przejdź',
        onRender: row => (
          <SuccessButton
            onClick={() => {
              navigate(`/waste-cards/${row.id}`);
            }}
          >
            Przejdź
          </SuccessButton>
        ),
      },
    ],
  };

  const handleClose = () => {
    setIsOpenWasteCardPopup(false);
  };

  const handlePreviousMonth = () => {
    setFilterDate(moment(filterDate).subtract(1, 'month').toISOString());
  };

  const handleNextMonth = () => {
    setFilterDate(moment(filterDate).add(1, 'month').toISOString());
  };

  return (
    <div className="WasteCards-module">
      <h2>Karty odpadów</h2>
      <div>
        <Button color="secondary" onClick={handlePreviousMonth}>
          {'<'}
        </Button>
        {moment(filterDate).format('MMMM YYYY')}{' '}
        {/* Wyświetl nazwę aktualnego miesiąca i roku */}
        <Button color="secondary" onClick={handleNextMonth}>
          {'>'}
        </Button>
      </div>
      <AdvancedTableWrapper config={config} data={wasteCards} />
      <div className="action-buttons-container">
        <Button color="secondary" onClick={() => setIsOpenWasteCardPopup(true)}>
          Dodaj kartę odpadów
        </Button>

        {selected.length > 0 && (
          <>
            <Button
              color="secondary"
              onClick={async () => {
                await Connector.postWasteCardsAsNoInvoice(selected);
                fetchData();
              }}
            >
              Oznacz jako bez faktury
            </Button>

            <Button
              color="secondary"
              onClick={() => {
                navigate(`/invoices/Faktura_klienta/new`, {
                  state: {
                    selectedWasteCards: selected,
                  },
                });
              }}
            >
              Wstaw fakture klienta
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                navigate(`/invoices/Samofaktura/new`, {
                  state: {
                    selectedWasteCards: selected,
                  },
                });
              }}
            >
              Wystaw samofakture
            </Button>
          </>
        )}
      </div>
      {isOpenWasteCardPopup && (
        <Popup handleClose={handleClose} isOpen={true} title="Karta odpadów">
          <AddEditWasteCardPopup
            clientId={clientId}
            refresh={() => fetchData()}
            onClose={handleClose}
          />
        </Popup>
      )}
    </div>
  );
};

export default WasteCards;
