import AdvancedTableWrapper from 'components/AdvancedTableWrapper';
import AdvancedFilter from 'modules/AdvancedFilter';
import { useEffect, useState } from 'react';

const FetchListTable = ({ toggleOpenDialog, setValue, config }) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  const initialFilters = {};
  config.filters?.filter(filter => {
    if (filter.isForced) {
      initialFilters[filter.id] = filter.initialValue;
    }
  });

  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(config.pagination);

  const fetchData = async params => {
    const response = await config.connectorList(params);
    if (config.pagination) {
      setPagination(response.data.pagination);
    }
    if (Array.isArray(response.data)) {
      setItems(response.data);
    } else {
      setItems(response.data.data);
    }
  };

  useEffect(() => {
    fetchData({
      page,
      ...filters,
    });
  }, [page, filters]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const tableConfig = {
    columns: config.columns,
    onClick: id => {
      toggleOpenDialog();
      setValue(id);
    },
    pagination: {
      ...pagination,
      setPage,
    },
  };

  return (
    <div>
      {config.filters && (
        <AdvancedFilter config={config.filters} setFilters={setFilters} />
      )}
      <AdvancedTableWrapper config={tableConfig} data={items} />
    </div>
  );
};

export default FetchListTable;
