import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import AddSinglePoint from 'pages/Deliveries/SingleDelivery/AddSinglePoint/AddSinglePoint';
import { DangerButton, SuccessButton } from 'components/CustomeMaterial';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';
import Connector from 'connector';

import './LocationListInput.scss';

const LocationListInput = ({ id, name }) => {
  const [locationData, setLocationData] = useState([]);
  const formikContext = useFormikContext();

  const value = formikContext.values[name || id];

  useEffect(() => {
    async function fetchData() {
      const ids = Array.isArray(value) ? value.join(',') : value;
      const location = await Connector.getLocationsByIds({
        ids: ids,
      });
      const locationData = location.data;
      setLocationData(locationData);
    }
    fetchData();
  }, [value.length]);

  const [popupState, setPopupState] = useState({
    isOpen: false,
  });

  const getIdsFromLocationData = locationData => {
    return locationData.map(item => item.id);
  };

  const config = {
    columns: [
      { id: 'localName', label: 'Nazwa' },
      { id: 'address', label: 'Adres' },
      { id: 'city', label: 'Miasto' },
      { id: 'postalCode', label: 'Kod pocztowy' },
      {
        id: 'action',
        label: 'Akcja',
        onRender: row => (
          <DangerButton
            onClick={() => {
              const updatedLocationData = locationData.filter(
                item => item.id !== row.id
              );
              setLocationData(updatedLocationData);
              const updatedValue = updatedLocationData.map(item => item.id);
              formikContext.setFieldValue(name || id, updatedValue);
            }}
          >
            Usuń
          </DangerButton>
        ),
      },
      {
        id: 'moveDown',
        label: '',
        onRender: row => {
          const index = locationData.findIndex(item => item.id === row.id);
          if (index < locationData.length - 1) {
            return (
              <div
                className="move-down"
                onClick={() => {
                  const index = locationData.findIndex(
                    item => item.id === row.id
                  );

                  const updatedLocationData = [...locationData];
                  const [removed] = updatedLocationData.splice(index, 1);
                  updatedLocationData.splice(index + 1, 0, removed);
                  setLocationData(updatedLocationData);
                  const ids = getIdsFromLocationData(updatedLocationData);
                  formikContext.setFieldValue(name || id, ids);
                }}
              >
                ↓
              </div>
            );
          }
        },
      },
      {
        id: 'moveUp',
        label: '',
        onRender: row => {
          const index = locationData.findIndex(item => item.id === row.id);
          if (index > 0) {
            return (
              <div
                className="move-up"
                onClick={() => {
                  const updatedLocationData = [...locationData];
                  const [removed] = updatedLocationData.splice(index, 1);
                  updatedLocationData.splice(index - 1, 0, removed);
                  setLocationData(updatedLocationData);
                  const ids = getIdsFromLocationData(updatedLocationData);
                  formikContext.setFieldValue(name || id, ids);
                }}
              >
                ↑
              </div>
            );
          }
        },
      },
    ],
  };

  return (
    <div className="client-list-input-wrapper">
      <AdvancedTableWrapper config={config} data={locationData} />
      <SuccessButton
        className="add-location-button"
        onClick={() => {
          setPopupState({ isOpen: true });
        }}
      >
        +
      </SuccessButton>
      {popupState.isOpen && (
        <AddSinglePoint
          onSelect={newValue => {
            formikContext.setFieldValue(id, [...value, newValue.locationId]);
            setPopupState({ isOpen: false });
          }}
          onClose={() => {
            setPopupState({ isOpen: false });
          }}
        />
      )}
    </div>
  );
};

export default LocationListInput;
