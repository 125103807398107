import moment from 'moment';
import { prepareInvoiceNumber } from 'helpers/invoices.helpers';

export const invoicesTableConfig = ({
  invoiceType,
  navigate,
  getSumOfCost,
  pagination,
  setPage,
}) => {
  return {
    columns: [
      {
        label: 'Nazwa dokumentu',
        id: 'number',
        onRender: row => {
          return <span>{prepareInvoiceNumber(row)}</span>;
        },
      },
      {
        label: 'Lokalizacja',
        id: 'location',
        onRender: row => {
          return (
            <span>
              <b>{row.location.localName}</b>, {row.location.address},{' '}
              {row.location.city}
            </span>
          );
        },
      },
      {
        label: 'Data wystawienia',
        id: 'createDate',
        onRender: row => {
          return <span>{moment(row.createDate).format('DD-MM-YYYY')}</span>;
        },
      },
      {
        label: 'Klient',
        id: 'client',
        onRender: row => {
          return <span>{row.client?.clientName || ''}</span>;
        },
      },
      {
        label: 'Typ',
        id: 'type',
        onRender: row => {
          return <span>{row.payType == 1 ? 'Przelew' : 'Gotówka'}</span>;
        },
      },
      {
        label: 'Wartość netto',
        id: 'issueDate',
        onRender: row => {
          const alertClass = row.paidDate ? '' : 'alertPaid';
          return <span className={alertClass}>{getSumOfCost(row)} PLN</span>;
        },
      },
    ],
    pagination: {
      ...pagination,
      setPage,
    },
    onClick: id => navigate(`${invoiceType}/${id}`),
  };
};
