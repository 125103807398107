import moment from 'moment';
import { pick } from 'lodash';

const initialData = {
  id: undefined,
  archive: 0,
  number: null,
  numberFormat: 1,
  clientInvoiceNumber: '',
  clientId: null,
  locationId: null,
  createDate: moment().format('YYYY-MM-DD'),
  executionDate: moment().format('YYYY-MM-DD'),
  placeOfCreate: '',
  isCorrectFor: 0,
  invoiceType: 1,
  payTerm: moment().add('days', 14).format('YYYY-MM-DD'),
  payType: 0,
  reasonOfCorrection: 0,
  paidDate: null,
  invoiceItems: [],
  correctionInvoiceItems: [],
  client: null,
  proformaId: null,
};

export const SingleInvoiceSelector = payloadData => {
  return {
    ...initialData,
    ...payloadData,
  };
};

export const SingleInvoiceByWasteCardsSelector = ({
  wasteCards,
  invoiceType,
  invoiceTermConstants,
}) => {
  const firstWasteCard = wasteCards[0];
  const client = firstWasteCard?.client;
  const location = firstWasteCard?.location;
  const priceConfig = JSON.parse(location.priceConfig);

  const invoiceItems = wasteCards.map(item => ({
    archive: null,
    name: 5,
    amount: item.count,
    nettoUnitPrice: priceConfig[5] || 0,
    vatValue: 23, // newVat
    wasteCardId: item.id,
  }));
  return {
    ...initialData,
    number: null,
    numberFormat: 1,
    clientInvoiceNumber: '',
    clientId: client.id,
    locationId: location.id,
    createDate: moment().format('YYYY-MM-DD'),
    executionDate: moment(firstWasteCard.transferDate).format('YYYY-MM-DD'),
    placeOfCreate: '',
    invoiceType,
    isCorrectFor: 0,
    payTerm: moment()
      .add('days', invoiceTermConstants[client.payTimeType])
      .format('YYYY-MM-DD'),
    payType: location.payment,
    reasonOfCorrection: 0,
    paidDate: null,
    invoiceItems,
    client,
  };
};

export const singleInvoicePreSendSelector = data => {
  return {
    ...pick(data, [
      'number',
      'numberFormat',
      'clientInvoiceNumber',
      'clientId',
      'locationId',
      'createDate',
      'executionDate',
      'placeOfCreate',
      'invoiceType',
      'isCorrectFor',
      'payTerm',
      'payType',
      'reasonOfCorrection',
      'paidDate',
      'proformaId',
      'comments',
    ]),
    id: data.id || undefined,
    correctionInvoiceItems: data.correctionInvoiceItems,
    invoiceItems: data.invoiceItems.map(item => ({
      amount: item.amount,
      id: item.id || undefined,
      name: item.name,
      nettoUnitPrice: item.nettoUnitPrice,
      vatValue: item.vatValue,
      wasteCardId: item.wasteCardId,
    })),
  };
};
