import { useEffect, useRef, useState } from 'react';
import Connector from 'connector';

import './SinglePoint.scss';
import { Formik } from 'formik';
import { AlertButton } from 'components/CustomeMaterial';

import { FaArrowAltCircleUp, FaArrowAltCircleDown } from 'react-icons/fa';
import { IoTrashBin } from 'react-icons/io5';

import useConfig from 'hooks/useConfig';
import useDelivery from 'hooks/useDelivery';
import { TextInput } from 'components/CustomeMaterial';
import { orderPreFormSelector } from './SinglePointSelectors';

const SinglePoint = ({ locationId, index, forceRerender, isLast }) => {
  const [location, setLocation] = useState(null);

  const prevProps = useRef({ locationId, index });

  const { PAY_TYPES, PRODUCTS, UNITS } = useConfig();
  const filteredProducts = PRODUCTS.filter(item => !item.archive);
  const formProducts = orderPreFormSelector(filteredProducts);

  const { points, setPoints } = useDelivery();

  const [initialState, setInitialState] = useState({
    products: points?.[index]?.itemsConfig || formProducts,
  });

  const fetchLocation = async () => {
    const location = await Connector.getLocation(locationId);
    const priceConfig = JSON.parse(location.data.priceConfig);

    if (!points?.[index].itemsConfig) {
      const newPoints = points.map((point, currentIndex) => {
        if (currentIndex === index) {
          const adjustedItemsConfig = formProducts.map(product => {
            return {
              ...product,
              count: 0,
              price: priceConfig[product.productId],
            };
          });
          setInitialState({ products: adjustedItemsConfig });

          return { ...point, itemsConfig: adjustedItemsConfig };
        }
        return point;
      });

      setPoints(newPoints);
    }

    setLocation(location.data);
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    if (prevProps.current.locationId !== locationId) {
      forceRerender();
    }
  }, [locationId]);

  const movePoint = direction => {
    const newPoints = [...points];

    if (direction === 'up') {
      newPoints[index] = points[index - 1];
      newPoints[index - 1] = points[index];
    } else {
      newPoints[index] = points[index + 1];
      newPoints[index + 1] = points[index];
    }

    setPoints(newPoints);
  };

  if (!location) {
    return <div>Loading...</div>;
  }

  return (
    <div className="SinglePoint-module">
      <div className="location-description card">
        <h3 className="page-title">Punkt {index + 1}</h3>
        <br></br>
        <h3>Lokalizacja:</h3>
        <p>
          <b>Nazwa lokalizacji:</b> {location.localName}
        </p>
        <p>
          <b>Adres:</b> {location.city}, {location.postalCode},{' '}
          {location.address}
        </p>
        <p>
          <b>Telefon:</b> {location.phone}
        </p>
        <h3>Dane klienta:</h3>
        <p>
          <b>Nazwisko:</b> {location.clients.clientName}
        </p>
        <p>
          <b>Nazwa firmy:</b> {location.clients.companyName}
        </p>
        <p>
          <b>Rodzaj płatności: </b> {PAY_TYPES[location.payment]}
        </p>
      </div>
      <div className="order-form">
        <p className="info">Cena dotyczy jednej jednostki.</p>

        <Formik
          initialValues={initialState}
          onSubmit={(values, { setSubmitting }) => {
            const newValues = {
              ...values,
              products: values.products.map(product => ({
                ...product,
                count: String(product.count || 0).replace(',', '.'),
                price: String(product.price || 0).replace(',', '.'),
              })),
            };

            setSubmitting(false);
            setInitialState(newValues);

            const modifiedPoints = points.map((point, currentIndex) => {
              if (currentIndex === index) {
                return { ...point, itemsConfig: newValues.products };
              }
              return point;
            });
            setPoints(modifiedPoints);
            forceRerender();
          }}
        >
          {({ dirty, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {formProducts.map((product, index) => (
                <div key={index} className="product-row">
                  <p>{product.name}</p>
                  <TextInput
                    id={`products.${index}.count`}
                    placeholder={` (${UNITS[product.unitId]})`}
                  />
                  <TextInput
                    id={`products.${index}.price`}
                    placeholder="Cena netto"
                  />
                </div>
              ))}
              {dirty && (
                <AlertButton type="submit">Potwierdź zmiany</AlertButton>
              )}
            </form>
          )}
        </Formik>
        <div className="btn-container">
          {index > 0 && <FaArrowAltCircleUp onClick={() => movePoint('up')} />}
          {!isLast && (
            <FaArrowAltCircleDown onClick={() => movePoint('down')} />
          )}
          <IoTrashBin
            className="trash"
            onClick={() => setPoints(points.filter((_, i) => i !== index))}
          />
        </div>
      </div>
    </div>
  );
};

export default SinglePoint;
