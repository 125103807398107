// TEMPORARY FILE BEFORE LOCATION SECTION
import { Formik, Form, Field } from 'formik';
import { TextField, Button, Stack } from '@mui/material';

// TODO: To remove after location section
interface ISearchBar {
  searchByLocation: (arg: string) => void;
  clearFilters: () => void;
}

const SearchByLocation: React.FC<ISearchBar> = ({
  searchByLocation,
  clearFilters,
}) => {
  return (
    <div className="Clients-searchbar">
      <h4>Wyszukaj po nazwie lokalu</h4>
      <Formik
        initialValues={{ locationName: '' }}
        onSubmit={(values, { setSubmitting }) => {
          searchByLocation(values.locationName);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="searchbar location-searchbar">
              <Field
                name="locationName"
                as={TextField}
                id="locationName"
                placeholder="Nazwa lokalizacji"
                variant="outlined"
              />
            </div>
            <div className="search-btn">
              <Stack spacing={1} direction={'row'}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Szukaj
                </Button>
                <Button
                  type="reset"
                  variant="outlined"
                  color="warning"
                  onClick={clearFilters}
                >
                  Wyczyść
                </Button>
              </Stack>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchByLocation;
