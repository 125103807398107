import './Alert.scss';
import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { IAlert } from './Alert.types';

const Alert = () => {
  const initialState: IAlert = {
    isOpen: false,
    alertTitle: '',
    alertDescription: '',
    isError: false,
  };

  const [state, setState] = useState<IAlert>(initialState);

  let timer: ReturnType<typeof setInterval>;
  const startInterval = () => {
    timer = setTimeout(() => setState(initialState), 3000);
  };

  useEffect(() => {
    startInterval();
    return () => {
      clearTimeout(timer);
    };
  }, [state?.isOpen]);

  document.showAlert = (isError, alertTitle, alertDescription) => {
    if (state?.isOpen && isError) {
      return;
    }
    setState({
      isOpen: true,
      alertTitle,
      alertDescription,
      isError,
    });
  };

  if (state?.isOpen) {
    return (
      <div className={`Alert-module${state?.isError ? ' error' : ''}`}>
        <div className="Alert-module-header">
          <h3>{state?.alertTitle} </h3>
          <span>{state?.isError ? <FaXmark /> : <FaCheck />}</span>
        </div>
        <p>{state?.alertDescription}</p>
      </div>
    );
  }

  return null;
};

export default Alert;
