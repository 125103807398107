import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useConfig from 'hooks/useConfig';
import Connector from 'connector';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/';
import { Search } from '@mui/icons-material';
import SearchWasteCards from './SearchWasteCards.js';
import { SuccessButton } from 'components/CustomeMaterial';

const { wasteCardsTableConfig } = require('./config.js');

const WasteCards = () => {
  const navigate = useNavigate();
  const { WASTE_DOCUMENT_NUMBER_FORMATS, UNITS, GLOBAL_CONFIG } = useConfig();
  const [wasteCards, setWasteCards] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPages: 2,
    totalRecords: 0,
  });

  const fetchData = async (params = {}) => {
    const response = await Connector.getWasteCards({
      ...params,
      sort: 'transferDate',
    });

    setPagination(response.data.pagination);
    setWasteCards(response?.data?.data || []);
  };

  useEffect(() => {
    fetchData({
      page,
      ...filters,
    });
  }, [filters, page]);

  return (
    <div className="WasteCards-page">
      <div className="button-container">
        <SuccessButton onClick={() => navigate('/waste-cards/new')}>
          Dodaj nową kartę
        </SuccessButton>
      </div>
      <div className="card">
        <h4 className="page-title">Karty odpadów</h4>

        <SearchWasteCards setFilters={setFilters} />

        <AdvancedTableWrapper
          config={wasteCardsTableConfig({
            WASTE_DOCUMENT_NUMBER_FORMATS,
            GLOBAL_CONFIG,
            UNITS,
            pagination,
            setPage,
            navigate,
          })}
          data={wasteCards}
        />
      </div>
    </div>
  );
};

export default WasteCards;
