import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import './Clients.scss';

import ClientsTable from './ClientsTable/ClientsTable';
import ClientsSearchbar from './ClientsSearchbar';
import SearchByLocation from './SearchByLocation/SearchByLocation';
import Switch from '@mui/material/Switch';
import AddClient from '../../modules/AddClient/AddClient';
import Popup from '../../modules/Popup/Popup';
import Popup2 from 'modules/Popup2';

import Connector from '../../connector';

const Clients = () => {
  const [clients, setClients] = useState({
    data: [],
    pagination: {
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      totalRecords: 0,
    },
  });

  const [page, setPage] = useState(1);
  // TODO: To remove after location section
  const [searchLocationName, setSearchLocationName] = useState('');
  const [showLocationFormMode, setShowLocationFormMode] = useState(false);

  const [searchData, setSearchData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [sortOption, setSortOption] = useState(['']);
  const onClearFilters = () => {
    setSearchData({});
    fetchData();
  };

  const fetchData = async () => {
    // TODO: To remove after location section
    if (showLocationFormMode) {
      const response = await Connector.getClientsByLocationName({
        page,
        locationName: searchLocationName,
        sortBy: sortOption,
      });
      setClients(response?.data);
      setPage(response?.data?.pagination?.currentPage);
    } else {
      const response = await Connector.getClients({
        page,
        ...searchData,
        sortBy: sortOption,
      });
      setClients(response?.data);
      setPage(response?.data?.pagination?.currentPage);
    }
  };

  const searchClients = async searchData => {
    setPage(1);
    setSearchData(searchData);
  };

  useEffect(() => {
    fetchData();
  }, [page, searchData, sortOption, searchLocationName]);

  return (
    <div className="Clients-page">
      <div className="Clients-page-wrapper">
        <div className="Clients-header">
          <h3>Lista klientów</h3>
          <div>
            <Stack spacing={1} direction={'row'}>
              <Popup2
                openButtonText="Dodaj klienta"
                title="Dodaj klienta"
                component={AddClient}
                componentProps={{
                  refetchClients: () => fetchData(),
                }}
              />
            </Stack>
          </div>
        </div>
        <Switch
          checked={showLocationFormMode}
          onChange={() => setShowLocationFormMode(!showLocationFormMode)}
          name="mySwitch"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />{' '}
        <label>Wyszukiwanie po lokalu</label>
        {showLocationFormMode ? (
          <SearchByLocation
            searchByLocation={setSearchLocationName}
            clearFilters={() => onClearFilters()}
          />
        ) : (
          <ClientsSearchbar
            searchClients={searchClients}
            clearFilters={() => onClearFilters()}
          />
        )}
        {clients?.data?.length !== 0 ? (
          <ClientsTable
            clientsData={clients}
            onSort={setSortOption}
            setPage={setPage}
          />
        ) : (
          <div>Brak rezultatów</div>
        )}
      </div>
    </div>
  );
};

export default Clients;
