import { TextInput, DateInput, Dropdown } from 'components/CustomeMaterial';
import { map } from 'lodash';

import { Button, Grid } from '@mui/material';
import FetchListPickerInput from 'modules/FetchListPickerInput';

const AddEditWasteCardFormContent = ({ onClose }) => {
  return (
    <div className="AddEditWasteCardFormContent-module">
      <Grid container>
        <Grid item xs={12} md={6}>
          <FetchListPickerInput name="driver" />
        </Grid>

        <Grid item xs={12} md={6}>
          <DateInput id="transferDate" placeholder="Data dostarczenia" />
        </Grid>

        <Grid item xs={12} md={6}>
          <FetchListPickerInput name="location" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput id="count" placeholder="Ilość" />
        </Grid>
      </Grid>

      <Button type="submit" variant="contained" color="secondary">
        Zapisz
      </Button>
      <Button
        type="button"
        variant="contained"
        color="warning"
        onClick={() => onClose()}
      >
        Anuluj
      </Button>
    </div>
  );
};

export default AddEditWasteCardFormContent;
