import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  clientId: Yup.string().required('Pole wymagane'),
  locationId: Yup.string().required('Pole wymagane'),
  count: Yup.number()
    .required('Pole wymagane')
    .min(1, 'Wartość musi być większa od 0'),
  transferDate: Yup.string().required('Pole wymagane'),
  driverId: Yup.string().required('Pole wymagane'),
  wasteNumber: Yup.string().required('Pole wymagane'),
});
