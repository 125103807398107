import { Button } from '@mui/material';
import SingleNote from './SingleNote';
import { INotesProps } from './notes.types';
import { INote } from 'interfaces/Client.types';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import AddEditNote from '../../../../modules/AddEditNote/AddEditNote';
import Popup from '../../../../modules/Popup/Popup';
import { useState } from 'react';

const Notes: React.FC<INotesProps> = ({ notes, onRefreshClient }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
    onRefreshClient();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Notatka</TableCell>
              <TableCell>Autor</TableCell>
              <TableCell>Data</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {notes.map((note: INote) => (
            <SingleNote
              note={note}
              key={note?.id}
              onRefreshClient={() => handleClose()}
            />
          ))}
        </Table>
      </TableContainer>
      <div className="SingleClient-notes-btn">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(true)}
        >
          Dodaj notatke
        </Button>
      </div>
      {isOpen && (
        <Popup isOpen={isOpen} handleClose={handleClose} title="Dodaj notatkę">
          <AddEditNote handleClose={() => handleClose()} />
        </Popup>
      )}
    </>
  );
};

export default Notes;
