import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import './FetchListPickerInput.scss';
import { useFormikContext } from 'formik';
import PrimaryButton from 'components/CustomeMaterial/SuccessButton';
import FetchListTable from './FetchListTable';
import listPickerConfig from 'constants/listPickerConfigs';

const FetchListPickerInput = ({ name, postFetchSelected, expandConfig }) => {
  const config = listPickerConfig[name](expandConfig);
  const [open, setOpen] = useState(false);
  const formik = useFormikContext();
  const [selected, setSelected] = useState();

  const fetchSelected = async selectedId => {
    const response = await config.connectorSelected(selectedId);

    setSelected(response?.data);
    if (postFetchSelected) {
      postFetchSelected({
        formikInstance: formik,
        response: response.data,
      });
    }
  };

  useEffect(() => {
    const selectedId = formik?.values?.[config.id] || null;
    if (selectedId) {
      fetchSelected(selectedId);
    }
  }, [formik?.values?.[config.id]]);

  const toggleOpenDialog = () => {
    setOpen(!open);
  };

  const getInputKey = () => {
    const isFunction = typeof config.displayInputKey === 'function';

    if (!selected) return 'Wybierz';

    if (isFunction) {
      return config.displayInputKey(selected);
    }
    return selected?.[config.displayInputKey];
  };

  return (
    <div className="FetchListPickerInput-module">
      <label>{config.placeholder}</label>
      <div className="fake-input" onClick={() => toggleOpenDialog()}>
        {getInputKey(config.displayInputKey)}
      </div>
      {formik.errors[config.id] && (
        <span className="validation-error-description">
          {formik.errors?.[config.id]}
        </span>
      )}

      <Dialog
        open={open}
        onClose={() => toggleOpenDialog()}
        className="FetchListPickerInput-Dialog"
      >
        <DialogContent className="dial-2">
          {open && (
            <FetchListTable
              config={config.tableConfig}
              toggleOpenDialog={toggleOpenDialog}
              setValue={id => formik.setFieldValue(config.id, String(id))}
            />
          )}
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => toggleOpenDialog()}>
            Zamknij
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FetchListPickerInput;
