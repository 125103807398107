import React, { useState, useEffect } from 'react';

import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import './AddEditDeliveryTemplatePopup.scss';
import FetchListPickerInput from 'modules/FetchListPickerInput';
import LocationListInput from 'components/LocationListInput/LocationListInput';
import {
  TextInput,
  SuccessButton,
  WarningButton,
  PrimaryButton,
  DangerButton,
} from 'components/CustomeMaterial';
import { useNavigate } from 'react-router-dom';
import Connector from 'connector';

const AddEditDeliveryTemplatePopup = ({ onClose, deliveryTemplateId }) => {
  const navigate = useNavigate();
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (deliveryTemplateId) {
        const response = await Connector.getDeliveryTemplateById(
          deliveryTemplateId
        );
        setTemplate(response?.data?.data);
      } else {
        setTemplate({});
      }
    };

    fetchData();
  }, [deliveryTemplateId]);

  if (template === null) {
    return <p>Loading...</p>;
  }

  const initialValues = {
    name: '',
    driverId: null,
    locationsIds: [],
    ...template,
  };

  return (
    <div className="AddEditDeliveryTemplatePopup-module">
      <FormikWrapper
        initialValues={initialValues}
        onSubmit={async values => {
          const preparedPayload = {
            ...values,
            locationsIds: values.locationsIds.join(','),
          };
          await Connector.postDeliveryTemplate(preparedPayload);
          onClose();
        }}
      >
        <div className="inputs-container">
          <TextInput id="name" placeholder="Nazwa trasy" />
          <FetchListPickerInput name="driver" />
        </div>
        <LocationListInput id="locationsIds" />

        <div className="button-container">
          {deliveryTemplateId && (
            <PrimaryButton
              onClick={() => {
                navigate(`/deliveries/new`, {
                  state: {
                    selectedDeliveryTemplate: deliveryTemplateId,
                  },
                });
              }}
            >
              Dodaj do tras
            </PrimaryButton>
          )}
          <SuccessButton type="submit">Zapisz</SuccessButton>

          <WarningButton onClick={onClose}>Anuluj</WarningButton>
          {deliveryTemplateId && (
            <DangerButton
              onClick={async () => {
                await Connector.deleteDeliveryTemplate(deliveryTemplateId);
                onClose();
              }}
            >
              Usuń
            </DangerButton>
          )}
        </div>
      </FormikWrapper>
    </div>
  );
};

export default AddEditDeliveryTemplatePopup;
