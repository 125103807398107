import moment from 'moment';

import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import { DateInput, SuccessButton } from 'components/CustomeMaterial';

import './WasteCardsGenerator.scss';
import Connector from 'connector';
import FetchListPickerInput from 'modules/FetchListPickerInput';

const WasteCardsGenerator = () => {
  const initialValues = {
    startDate: moment().startOf('month').toISOString(),
    endDate: moment().endOf('month').toISOString(),
    clientId: null,
    locationId: null,
    driverId: '',
  };

  const viewXLS = xlsBlob => {
    const url = window.URL.createObjectURL(
      new Blob([xlsBlob], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = 'KPO.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const onSubmitHandler = async params => {
    const response = await Connector.getWasteCardXLS(params);
    const xlsBlob = await response.data;
    viewXLS(xlsBlob);
  };

  return (
    <div className="WasteCardsGenerator-page">
      <div className="card">
        <h4 class="page-title">Generator KPO</h4>
        <FormikWrapper initialValues={initialValues} onSubmit={onSubmitHandler}>
          <div className="form-container">
            <DateInput id="startDate" placeholder="Początkowa data" />
            <DateInput id="endDate" placeholder="Końcowa data" />
            <FetchListPickerInput name="client" />
            <FetchListPickerInput name="location" />
            <FetchListPickerInput name="driver" />
          </div>
          <SuccessButton type="submit">Generuj</SuccessButton>
        </FormikWrapper>
      </div>
    </div>
  );
};

export default WasteCardsGenerator;
