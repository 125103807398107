import Root from './Root';
import './App.scss';
import { AuthProvider } from './providers/AuthProvider';
import { ConfigProvider } from './providers/ConfigProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Alert from './modules/Alert/Alert';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <ConfigProvider>
            <Root />
          </ConfigProvider>
        </AuthProvider>
      </LocalizationProvider>
      <Alert />
    </div>
  );
}

export default App;
