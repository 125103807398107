export const getNettoValue = ({ amount, nettoUnitPrice }) => {
  if (!amount || !nettoUnitPrice) return 0;
  return (parseFloat(amount) * parseFloat(nettoUnitPrice)).toFixed(2);
};

export const getBruttoValue = ({ amount, nettoUnitPrice, vatValue }) => {
  if (!amount || !nettoUnitPrice || !vatValue) return 0;
  return (
    parseFloat(amount) *
    (parseFloat(nettoUnitPrice) * (Number(vatValue) / 100 + 1))
  ).toFixed(2);
};

export const getSummaryNetto = values => {
  const sum = values.reduce((acc, item) => {
    return acc + item.amount * item.nettoUnitPrice;
  }, 0);
  return sum.toFixed(2);
};

export const getSummaryBrutto = values => {
  const sum = values.reduce((acc, item) => {
    return (
      acc + item.amount * (item.nettoUnitPrice * (item.vatValue / 100 + 1))
    );
  }, 0);

  return sum.toFixed(2);
};
