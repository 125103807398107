import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';
import Connector from 'connector';

import DeliveriesSearch from './DeliveriesSearch';
import DeliveryTemplates from './DeliveryTemplates/DeliveryTemplates';

import './Deliveries.scss';
import { SuccessButton } from 'components/CustomeMaterial';
import Popup2 from 'modules/Popup2/Popup2';

const Deliveries = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 0,
  });
  const [filters, setFilters] = useState({});

  const [deliveries, setDeliveries] = useState({
    data: [],
  });

  const [loading, setLoading] = useState(true);

  const fetchData = async params => {
    const response = await Connector.getDeliveries(params);
    setPagination(response?.data?.pagination || {});
    setDeliveries(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData({
      page,
      ...filters,
    });
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchData({
      page,
      ...filters,
    });
  }, [filters]);

  if (loading) {
    return <p>Pobieranie danych...</p>;
  }

  const tableConfig = {
    columns: [
      {
        id: 'date',
        label: 'Data',
        onRender: row => moment(row.date).format('DD-MM-YYYY'),
      },
      {
        id: 'driver.login',
        label: 'Kierowca',
        onRender: row => row.driver?.login || '',
      },
      { id: 'name', label: 'Nazwa trasy' },
    ],
    pagination: {
      ...pagination,
      setPage,
    },
    onClick: id => navigate(`${id}`),
  };

  return (
    <div className="Deliveries-page card">
      <h4 className="page-title">Trasy dostaw</h4>
      <div className="btn-container">
        <Popup2
          title="Szablony"
          component={DeliveryTemplates}
          openButtonText="Szablony"
        />

        <SuccessButton onClick={() => navigate('new')}>
          Dodaj jednorazową trasę
        </SuccessButton>
      </div>

      <DeliveriesSearch setFilters={setFilters} />

      <AdvancedTableWrapper
        data={deliveries?.data || []}
        config={tableConfig}
      />
    </div>
  );
};

export default Deliveries;
