import connector from 'connector';
import { prepareInvoiceNumber } from 'helpers/invoices.helpers';

export const client = () => {
  return {
    id: 'clientId',
    placeholder: 'Klient',
    displayInputKey: 'clientName',
    connectorSelected: connector.getClient,
    tableConfig: {
      connectorList: connector.getClients,
      columns: [
        { id: 'clientName', label: 'Właściciel' },
        { id: 'companyName', label: 'Nazwa firmy' },
        { id: 'city', label: 'Adres' },
        { id: 'country', label: 'Kraj' },
        { id: 'nip', label: 'NIP' },
      ],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
        totalRecords: 0,
      },
      filters: [
        { initialValue: '', id: 'companyName', placeholder: 'Nazwa firmy' },
        { initialValue: '', id: 'city', placeholder: 'Miasto' },
        { initialValue: '', id: 'address', placeholder: 'Adres' },
        { initialValue: '', id: 'nip', placeholder: 'NIP' },
      ],
    },
  };
};

export const location = expandConfig => {
  const config = {
    id: 'locationId',
    placeholder: 'Lokalizacja',
    displayInputKey: 'localName',
    connectorSelected: connector.getLocation,
    tableConfig: {
      connectorList: connector.getLocations,
      columns: [
        { id: 'localName', label: 'Nazwa lokalu' },
        { label: 'Właściciel', onRender: row => row.clients.clientName },
        { label: 'Firma', onRender: row => row.clients.companyName },
        { id: 'city', label: 'Adres' },
        { id: 'nip', label: 'NIP' },
      ],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
        totalRecords: 0,
      },
      filters: [
        { initialValue: '', id: 'localName', placeholder: 'Nazwa lokalu' },
        { initialValue: '', id: 'city', placeholder: 'Miasto' },
        { initialValue: '', id: 'address', placeholder: 'Adres' },
      ],
    },
  };

  if (expandConfig?.filters) {
    config.tableConfig.filters.push(...expandConfig.filters);
  }

  return config;
};

export const driver = () => {
  return {
    id: 'driverId',
    placeholder: 'Kierowca',
    displayInputKey: 'login',
    connectorSelected: connector.getDriver,
    tableConfig: {
      connectorList: connector.getDrivers,
      columns: [
        {
          id: 'login',
          label: 'Nazwa',
        },
        {
          id: 'code',
          label: 'Kod',
        },
      ],
    },
  };
};

export const supplier = () => {
  return {
    id: 'supplierId',
    placeholder: 'Dostawca',
    displayInputKey: 'name',
    connectorSelected: connector.getSupplier,
    tableConfig: {
      connectorList: connector.getSuppliers,
      columns: [
        {
          id: 'name',
          label: 'Nazwa',
        },
      ],
    },
  };
};

export const invoice = () => {
  return {
    id: 'invoiceId',
    placeholder: 'Faktura',
    displayInputKey: selected => prepareInvoiceNumber(selected),
    connectorSelected: connector.getInvoice,
    tableConfig: {
      connectorList: connector.getInvoices,
      columns: [
        {
          id: 'number',
          label: 'Numer faktury',
          onRender: row => prepareInvoiceNumber(row),
        },
        {
          id: 'location',
          label: 'Lokalizacja',
          onRender: row => {
            return (
              <span>
                <b>{row.location.localName}</b>, {row.location.address},{' '}
                {row.location.city}
              </span>
            );
          },
        },
        {
          id: 'client',
          label: 'Klient',
          onRender: row => row.client.companyName,
        },
        {
          id: 'nip',
          label: 'NIP',
          onRender: row => row.client.nip,
        },
      ],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
        totalRecords: 0,
      },
      filters: [
        { initialValue: '', id: 'companyName', placeholder: 'Nazwa firmy' },
        { initialValue: '', id: 'localName', placeholder: 'Nazwa lokalu' },
        { initialValue: '', id: 'city', placeholder: 'Miasto' },
        { initialValue: '', id: 'address', placeholder: 'Adres' },
        { initialValue: '', id: 'nip', placeholder: 'NIP' },
      ],
    },
  };
};

export default {
  client,
  location,
  driver,
  supplier,
  invoice,
};
