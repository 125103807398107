import { Field } from 'formik';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';

const CashInput = ({ id, name, placeholder, type }) => {
  const formik = useFormikContext();

  const handleChange = e => {
    const value = e.target.value.replace(/,/g, '.');
    formik.setFieldValue(id || name, value);
  };

  return (
    <div className="text-input-wrapper">
      <label>{placeholder}</label>
      <Field
        name={name || id}
        as={TextField}
        id={id}
        placeholder={placeholder}
        onChange={handleChange}
        type={type || 'text'}
      />
      {formik.errors[id] && (
        <span className="validation-error-description">
          {formik.errors[id]}
        </span>
      )}
    </div>
  );
};

export default CashInput;
