import { Field } from 'formik';
import { TextField } from '@mui/material';
import './TextInput.scss';
import { useFormikContext } from 'formik';

const TextInput = ({ id, name, placeholder, type }) => {
  const formik = useFormikContext();

  return (
    <div className="text-input-wrapper">
      <label>{placeholder}</label>
      <Field
        name={name || id}
        as={TextField}
        id={id}
        placeholder={placeholder}
        type={type || 'text'}
      />
      {formik.errors[id] && (
        <span className="validation-error-description">
          {formik.errors[id]}
        </span>
      )}
    </div>
  );
};

export default TextInput;
