import { useFormikContext } from 'formik';

const FormWarning = ({ id, message, validation }) => {
  const formik = useFormikContext();

  if (!validation(formik.values[id])) {
    return <></>;
  }

  return (
    <div className="form-warning">
      <span>{message}</span>
    </div>
  );
};

export default FormWarning;
