import { Button, Stack } from '@mui/material';

const PrimaryButton = ({ onClick, children, className, type }) => {
  return (
    <Button
      variant="contained"
      className={className}
      color="info"
      onClick={onClick}
      type={type}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
