import { Button, Stack } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

type ISuccessButtonProps = {
  onClick?: () => void;
  children?: string;
  className?: string;
  type?: string;
} & ButtonProps;

const PrimaryButton = ({
  onClick,
  children,
  className,
  type,
}: ISuccessButtonProps) => {
  return (
    <Button
      variant="contained"
      className={className}
      color="success"
      onClick={onClick}
      type={type}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
