import { useState, useEffect } from 'react';
import Connector from 'connector';
import Popup2 from 'modules/Popup2/Popup2';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';

import AddSuppliersPopup from './AddSuppliersForm/AddSuppliersFormWrapper';
import { DangerButton } from 'components/CustomeMaterial';

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState(null);

  const fetchData = () => {
    Connector.getSuppliers().then(response => {
      setSuppliers(response.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (suppliers === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Supplier-page">
      <div className="card">
        <h4 className="page-title">Dostawcy</h4>
        <div className="button-container">
          <Popup2
            afterClose={fetchData}
            component={AddSuppliersPopup}
            openButtonText="Dodaj dostawcę"
            title="Dodaj dostawcę"
          />
        </div>
        <AdvancedTableWrapper
          data={suppliers}
          config={{
            columns: [
              {
                id: 'name',
                label: 'Nazwa',
              },
              {
                id: 'description',
                label: 'Opis',
              },
              {
                id: 'contactInfo',
                label: 'Informacje kontaktowe',
              },
              {
                id: 'remove',
                label: 'Usuń',
                onRender: row => (
                  <DangerButton
                    onClick={() => {
                      Connector.deleteSupplier({ id: row.id }).then(fetchData);
                    }}
                  >
                    Usuń
                  </DangerButton>
                ),
              },
              {
                id: 'edytuj',
                label: 'Edytuj',
                onRender: row => (
                  <Popup2
                    afterClose={fetchData}
                    component={AddSuppliersPopup}
                    openButtonText="Edytuj"
                    componentProps={{ id: row.id }}
                    title="Edytuj dostawcę"
                  />
                ),
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default Suppliers;
