import Connector from 'connector';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Card } from '@mui/material';

import { useEffect, useState } from 'react';
import useConfig from 'hooks/useConfig';
import SingleInvoiceFormContent from './SingleInvoiceFormContent';
import {
  SingleInvoiceSelector,
  SingleInvoiceByWasteCardsSelector,
  singleInvoicePreSendSelector,
} from './SingleInvoiceSelector';
import { useParams } from 'react-router-dom';
import './SingleInvoice.scss';

const SingleInvoiceWrapper = () => {
  const { id, invoiceType } = useParams();
  const { INVOICE_PAY_TEMPS, GLOBAL_CONFIG: GC } = useConfig();
  const location = useLocation();
  const { state } = location;
  console.log({ state });
  const isNew = id === 'new';

  const [initialData, setInitialData] = useState(null);

  const fetchData = async () => {
    let response = null;

    if (!isNew) {
      response = await Connector.getInvoice(id);
    }

    if (response?.data) {
      const selectedData = SingleInvoiceSelector(response.data);
      setInitialData(selectedData);
    }
  };

  const fetchLastInvoiceNumber = async () => {
    const month = moment().format('MM');
    const year = moment().format('YYYY');
    const payload = await Connector.getLastInvoiceNumber({
      invoiceType,
      month,
      year,
      format: 1,
    });
    const number = payload?.data?.data?.number || 0;
    const selectedData = SingleInvoiceSelector({
      invoiceType,
      number: Number(number) + 1,
    });

    setInitialData(selectedData);
  };

  const fetchForWasteCards = async () => {
    let number = undefined;
    const wasteCardsIds = state.selectedWasteCards.join(',');
    const wasteCardsPayload = await Connector.getWasteCardsByIds(wasteCardsIds);

    const wasteCards = wasteCardsPayload?.data?.data;

    const selectedData = SingleInvoiceByWasteCardsSelector({
      wasteCards,
      invoiceType,
      invoiceTermConstants: INVOICE_PAY_TEMPS,
    });

    if (invoiceType === GC.INVOICE_TYPES.SELF_INVOICE) {
      const month = moment().format('MM');
      const year = moment().format('YYYY');
      const payload = await Connector.getLastInvoiceNumber({
        invoiceType,
        month,
        year,
        format: 1,
      });
      number = Number(payload?.data?.data?.number || 0) + 1;
    }
    setInitialData({
      ...selectedData,
      number,
    });
  };

  const onSend = async data => {
    const preparedPayload = singleInvoicePreSendSelector(data);
    const response = await Connector.postInvoice(preparedPayload);
    if (response?.data?.data) {
      const { invoiceType, id } = response.data.data;
      window.location.href = `/invoices/${invoiceType}/${id}`;
    }
  };

  useEffect(() => {
    if (state?.selectedWasteCards) {
      fetchForWasteCards();
    } else if (!isNew) {
      fetchData();
    } else {
      fetchLastInvoiceNumber();
    }
  }, []);

  if (initialData == null) {
    return <p>loading...</p>;
  } else {
    return (
      <div className="SingleInvoice-page">
        <Card>
          <Formik
            initialValues={initialData}
            onSubmit={values => onSend(values)}
          >
            <Form>
              <SingleInvoiceFormContent
                invoiceType={invoiceType}
                isNew={isNew}
              />
            </Form>
          </Formik>
        </Card>
      </div>
    );
  }
};

export default SingleInvoiceWrapper;
