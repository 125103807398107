// @ts-nocheck

import { IClient } from '../Clients.types';
import { useNavigate } from 'react-router-dom';

import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';

const ClientsTable: React.FC<ITableProps> = ({
  clientsData,
  onSort,
  setPage,
}) => {
  const navigate = useNavigate();

  const config = {
    columns: [
      { id: 'companyName', label: 'Nazwa firmy' },
      { id: 'clientName', label: 'Właściciel' },
      { id: 'city', label: 'Adres' },
      { id: 'country', label: 'Kraj' },
      { id: 'nip', label: 'NIP' },
      {
        id: 'vat',
        label: 'VAT',
        onRender: (row: IClient) => (row.isVat ? 'Tak' : 'Nie'),
      },
      {
        id: 'contract',
        label: 'Kontrakt',
        onRender: (row: IClient) => (row.contract ? 'Tak' : 'Nie'),
      },
    ],
    onSort: onSort,
    pagination: {
      ...clientsData.pagination,
      setPage: setPage,
    },
    onClick: (id: string) => navigate(`${id}`),
  };

  return (
    <div className="Clients-table">
      <AdvancedTableWrapper data={clientsData.data} config={config} />
    </div>
  );
};

export default ClientsTable;
