import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import Connector from 'connector';
import AddEditWasteCardFormContent from './AddEditWasteCardFormContent';

const AddEditWasteCardPopup = ({ clientId, refresh, onClose }) => {
  const initialData = {
    clientId,
    locationId: '',
    count: '0',
    transferDate: moment().toISOString(),
    driverId: null,
    noInvoice: '',
  };

  const onSend = async data => {
    await Connector.postWasteCard(data);
    refresh();
    onClose();
  };

  const validationSchema = Yup.object().shape({
    locationId: Yup.string().required('Pole wymagane'),
    count: Yup.number()
      .required('Pole wymagane')
      .min(1, 'Wartość musi być większa od 0'),
    transferDate: Yup.string().required('Pole wymagane'),
    driverId: Yup.string().required('Pole wymagane'),
  });

  return (
    <div>
      <Formik
        initialValues={initialData}
        onSubmit={values => onSend(values)}
        validationSchema={validationSchema}
      >
        <Form>
          <AddEditWasteCardFormContent clientId={clientId} onClose={onClose} />
        </Form>
      </Formik>
    </div>
  );
};

export default AddEditWasteCardPopup;
