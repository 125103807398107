export const orderPreFormSelector = products => {
  return products.map(item => {
    return {
      productId: item.id,
      name: item.name,
      unitId: item.unitId,
      vat: item.id == 5 ? 23 : 5, // newVat
      price: 0,
      count: 0,
    };
  });
};
