import { useState, useEffect } from 'react';
import Connector from 'connector';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';
import AddEditDeliveryTemplatePopup from './AddEditDeliveryTemplatePopup/AddEditDeliveryTemplatePopup';
import { WarningButton } from 'components/CustomeMaterial';
import Popup2 from 'modules/Popup2/Popup2';

const DeliveryTemplates = ({ onClose }) => {
  const [templates, setTemplates] = useState(null);

  const fetchData = async () => {
    const response = await Connector.getDeliveryTemplates();
    setTemplates(response?.data?.data || []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (templates === null) {
    return <p>Loading...</p>;
  }

  const config = {
    columns: [
      { id: 'name', label: 'Nazwa' },
      {
        id: 'driverId',
        label: 'Kierowca',
        onRender: row => row.driver.login,
      },
      {
        id: 'open',
        label: 'Otwórz',
        onRender: ({ id, ...rest }) => {
          return (
            <Popup2
              title={`Szablon ${rest.name}`}
              openButtonText="Otwórz"
              component={AddEditDeliveryTemplatePopup}
              componentProps={{ deliveryTemplateId: id }}
              afterClose={fetchData}
            />
          );
        },
      },
    ],
  };

  return (
    <div className="Delivery-Templates">
      <div className="button-container">
        <Popup2
          title="Dodaj nowy"
          component={AddEditDeliveryTemplatePopup}
          openButtonText="Dodaj"
          afterClose={fetchData}
        />
        <WarningButton onClick={onClose}>Anuluj</WarningButton>
      </div>
      <AdvancedTableWrapper config={config} data={templates} />
    </div>
  );
};

export default DeliveryTemplates;
