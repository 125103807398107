import { createContext, useState, useEffect } from 'react';

import Connector from 'connector';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  const fetchConfig = async () => {
    const response = await Connector.getConfig();
    window.frontConfig = response.data;
    setConfig(response.data);
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>
      {config ? children : 'Ładowanie...'}
    </ConfigContext.Provider>
  );
};
