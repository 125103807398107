import { Button, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

const FormContextButton = ({ onClick, children, className, type }) => {
  const formikContext = useFormikContext();

  return (
    <Button
      variant="contained"
      className={className}
      color="info"
      onClick={() => onClick(formikContext)}
      type={type}
    >
      {children}
    </Button>
  );
};

export default FormContextButton;
