import { useEffect, useState } from 'react';
import { Modal, Typography } from '@mui/material';
import './Popup2.scss';
import { SuccessButton } from 'components/CustomeMaterial';

const Popup2 = ({
  openCondition = false,
  openButtonText = 'Otwórz Popup',
  className = '',
  ButtonComponent = SuccessButton,
  title = '',
  component,
  componentProps,
  afterClose,
}) => {
  const [isOpen, setIsOpen] = useState(openCondition);
  const Component = component;

  useEffect(() => {
    setIsOpen(openCondition);
  }, [openCondition]);

  useEffect(() => {
    if (!isOpen) {
      afterClose && afterClose();
    }
  }, [isOpen]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ButtonComponent onClick={togglePopup}>{openButtonText}</ButtonComponent>
      <Modal open={isOpen} onClose={togglePopup}>
        <div className={`Popup2-module ${className}`}>
          <Typography variant="h5">{title}</Typography>
          <Component onClose={() => setIsOpen(false)} {...componentProps} />
        </div>
      </Modal>
    </>
  );
};

export default Popup2;
