import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { map } from 'lodash';

import SearchInvoice from './SearchInvoices';

import './Invoices.scss';
import PrimaryButton from 'components/CustomeMaterial/PrimaryButton';
import SuccessButton from 'components/CustomeMaterial/SuccessButton';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';
import Connector from 'connector';

import useConfig from 'hooks/useConfig';
import { invoicesTableConfig } from './Invoices.config';
import Popup2 from 'modules/Popup2';
import CSVGenerator from './CSVGenerator';

const getSumOfCost = row => {
  let sum = 0;
  row.invoiceItems.forEach(item => {
    sum += item.amount * item.nettoUnitPrice;
  });
  return sum.toFixed(2);
};

const Invoices = () => {
  const { GLOBAL_CONFIG: GC, DICTIONARIES: DC } = useConfig();
  const [invoiceType, setInvoiceType] = useState(1);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 0,
  });

  const navigate = useNavigate();

  const fetchData = async (params = {}) => {
    const response = await Connector.getInvoices({
      ...params,
      ...filters,
      page,
      invoiceType,
    });
    setPagination(response.data.pagination);
    setInvoices(response?.data?.data || []);
  };

  useEffect(() => {
    fetchData({
      page,
      ...filters,
    });
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchData({
      page,
      ...filters,
    });
  }, [filters, invoiceType]);

  const tableConfig = invoicesTableConfig({
    invoiceType,
    navigate,
    getSumOfCost,
    pagination,
    setPage,
  });

  return (
    <div className="Invoices-page">
      <div className="top-bar">
        <nav>
          {map(GC.INVOICE_TYPES, id => (
            <PrimaryButton
              key={id}
              className={id == invoiceType ? 'active' : 'unactive'}
              onClick={() => setInvoiceType(id)}
            >
              {DC.INVOICE_TYPES[id]}
            </PrimaryButton>
          ))}
        </nav>
        <div className="action-buttons">
          {[
            { name: 'Wygeneruj CSV', type: 'csv' },
            { name: 'Wygeneruj EPP', type: 'epp' },
          ].map(({ name, type }) => (
            <Popup2
              key={type}
              openButtonText={name}
              title={name}
              component={CSVGenerator}
              componentProps={{
                type,
                invoiceType,
              }}
            />
          ))}
          {invoiceType !== GC.INVOICE_TYPES.CORRECTION && (
            <SuccessButton
              onClick={() => navigate(`/invoices/${invoiceType}/new`)}
            >
              Nowa faktura
            </SuccessButton>
          )}
        </div>
      </div>
      <SearchInvoice setFilters={setFilters} />
      <AdvancedTableWrapper
        key={invoiceType}
        data={invoices}
        config={tableConfig}
      />
    </div>
  );
};

export default Invoices;
