import { useState } from 'react';
import { Card } from '@material-ui/core';
import './SearchInvoices.scss';
import { Formik, Form, Field } from 'formik';
import {
  TextInput,
  DateInput,
  SuccessButton,
  WarningButton,
  CheckboxInput,
} from 'components/CustomeMaterial';
import FetchListPickerInput from 'modules/FetchListPickerInput/FetchListPickerInput';
import FormikWrapper from 'components/FormikWrapper';

const SearchInvoices = ({ setFilters }) => {
  const [formKey, setFormKey] = useState(0);

  return (
    <div className="SearchInvoices-module" key={formKey}>
      <Card>
        <FormikWrapper
          initialValues={{}}
          onSubmit={values => setFilters(values)}
        >
          <div className="inputs-wrapper">
            <DateInput id="startDate" placeholder="Początkowa data" />
            <DateInput id="endDate" placeholder="Końcowa data" />
            <FetchListPickerInput name="client" />
            <FetchListPickerInput name="location" />
            <CheckboxInput id="notPaid" placeholder="Pokaż nieopłacone" />
          </div>
          <div className="controls-wrapper">
            <SuccessButton type="submit">Szukaj</SuccessButton>
            <WarningButton
              onClick={() => {
                setFilters({});
                setFormKey(formKey + 1);
              }}
            >
              Wyczyść
            </WarningButton>
          </div>
        </FormikWrapper>
      </Card>
    </div>
  );
};

export default SearchInvoices;
