import { Button, Stack } from '@mui/material';

type IWarningButtonProps = {
  onClick?: () => void;
  children?: string;
  className?: string;
};

const PrimaryButton = ({
  onClick,
  children,
  className,
}: IWarningButtonProps) => {
  return (
    <Button
      variant="contained"
      className={className}
      color="warning"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
