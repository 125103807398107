import { useEffect, useState } from 'react';
import useConfig from 'hooks/useConfig';
import {
  DateInput,
  Dropdown,
  PrimaryButton,
  WarningButton,
  TextInput,
  TextareaInput,
} from 'components/CustomeMaterial';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import moment from 'moment';
import Connector from 'connector';
import FetchListPickerInput from 'modules/FetchListPickerInput/FetchListPickerInput';

const AdmissionToMagazine = ({ onRefetch, onClose, id }) => {
  const [transaction, setTransaction] = useState(null);
  const isEdit = !!id;

  useEffect(() => {
    if (id) {
      Connector.getInventoryHistoryById(id).then(response => {
        setTransaction(response.data);
      });
    }
  }, []);

  if (id && transaction === null) {
    return <div>Loading...</div>;
  }

  const { PRODUCT_TYPES } = useConfig();
  const onSubmitHandler = async params => {
    if (isEdit) {
      await Connector.putInventoryHistory({ ...params, id });
    } else {
      await Connector.postInventoryHistory(params);
    }
    onRefetch();
    onClose();
  };

  const productsOptions = object =>
    Object.entries(object).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const initialValues = {
    productId: transaction?.productId || 1,
    changeDate: transaction
      ? moment(transaction.changeDate).toISOString()
      : moment().toISOString(),
    changeType: transaction?.changeType || 1,
    count: transaction?.count || '',
    supplierId: transaction?.supplierId || null,
    description: transaction?.description,
  };

  return (
    <div className="AdmissionToMagazine-popup table-filter">
      <FormikWrapper initialValues={initialValues} onSubmit={onSubmitHandler}>
        <div className="inputs-container">
          <Dropdown
            id="changeType"
            label="Rodzaj"
            options={[
              { value: 1, label: 'Przyjęcie' },
              { value: 2, label: 'Wydanie' },
            ]}
          />
          <Dropdown
            id="productId"
            label="Produkt"
            options={productsOptions(PRODUCT_TYPES)}
          />
          <DateInput id="changeDate" placeholder="Data" />
        </div>
        <div className="inputs-container">
          <TextInput id="count" placeholder="Ilość" />
          <FetchListPickerInput name="supplier" />
        </div>
        <div className="inputs-container">
          <TextareaInput id="description" placeholder="Opis" />
        </div>
        <div className="button-container">
          <PrimaryButton type="submit">
            {isEdit ? 'Edytuj' : 'Dodaj'}
          </PrimaryButton>
          <WarningButton onClick={onClose}>Anuluj</WarningButton>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default AdmissionToMagazine;
