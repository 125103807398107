import { Button } from '@mui/material';
import { Field, useFormikContext } from 'formik';

const GoByParamInputButton = ({
  children,
  hideCondition,
  onClick,
  ...rest
}) => {
  const formikContext = useFormikContext();
  const values = formikContext.values;

  if (hideCondition?.(values)) {
    return null;
  }

  return (
    <Button
      className={`GoByParamInputButton ${rest.className}`}
      variant="contained"
      onClick={() => {
        onClick(values);
      }}
      color="info"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default GoByParamInputButton;
