import './Login.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Button } from '@mui/material';
import { FormValues } from './Login.types';
import useAuth from '../../hooks/useAuth';

const Login = (): JSX.Element => {
  const { login } = useAuth();
  const initialValues: FormValues = {
    username: '',
    password: '',
  };

  const onSubmit = (values: FormValues): void => {
    login(values.username, values.password);
  };

  return (
    <div className="Login-page">
      <div className="Login-wrapper">
        <img src="/images/fehu-logo.png" alt="FEHU INVESTMENTS logo" />
        <div className="form-container">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
              <Field
                as={TextField}
                type="text"
                label="Username"
                name="username"
                variant="outlined"
                margin="normal"
                fullWidth
                autoComplete="username"
              />
              <ErrorMessage name="username" component="div" />

              <Field
                as={TextField}
                type="password"
                label="Password"
                name="password"
                variant="outlined"
                margin="normal"
                fullWidth
                autoComplete="current-password"
              />
              <ErrorMessage name="password" component="div" />

              <Button type="submit" variant="contained" color="primary">
                Log in
              </Button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
