import React, { useState, useEffect } from 'react';
import Connector from 'connector';
import AddSuppliersForm from './AddSuppliersForm';

const AddSuppliersFormWrapper = ({ id, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [supplier, setSupplier] = useState(null);

  useEffect(() => {
    if (id) {
      Connector.getSupplier({ id }).then(response => {
        setSupplier(response.data);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return <AddSuppliersForm supplier={supplier} {...props} />;
};

export default AddSuppliersFormWrapper;
