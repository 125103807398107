import { createContext, useState } from 'react';

export const DeliveryContext = createContext();

export const DeliveryProvider = ({ children }) => {
  const [points, setPoints] = useState([]);
  const [details, setDetails] = useState({
    driverId: null,
    name: '',
    date: null,
  });
  const [allowance, setAllowance] = useState({});

  return (
    <DeliveryContext.Provider
      value={{
        points,
        setPoints,
        details,
        setDetails,
        allowance,
        setAllowance,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};
