import {
  SuccessButton,
  TextInput,
  TextareaInput,
  WarningButton,
} from 'components/CustomeMaterial';
import connector from 'connector';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';

const AddSuppliersForm = ({ onClose, supplier }) => {
  const initialValues = {
    name: '',
    description: '',
    contactInfo: '',
    ...supplier,
  };

  const onSubmit = values => {
    connector.postSupplier(values).then(response => {
      onClose();
    });
  };

  return (
    <FormikWrapper initialValues={initialValues} onSubmit={onSubmit}>
      <div className="form-container">
        <TextInput id="name" placeholder="Nazwa" />
        <TextareaInput id="description" placeholder="Opis" />
        <TextareaInput id="contactInfo" placeholder="Informacje kontaktowe" />

        <SuccessButton type="submit">Zapisz</SuccessButton>
        <WarningButton onClick={onClose}>Zamknij</WarningButton>
      </div>
    </FormikWrapper>
  );
};
export default AddSuppliersForm;
