import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { IAddClient, IAddClientData } from './addClients.types';
import Connector from '../../connector';
import { useNavigate } from 'react-router-dom';
import { WarningButton } from 'components/CustomeMaterial';

const AddClient: React.FC<IAddClient> = ({ refetchClients, onClose }) => {
  const initialValues = {
    companyName: '',
    clientName: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    nip: '',
    regon: '',
    isVat: false,
    isSignedContract: false,
  };
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Nazwa firmy jest wymagana'),
    // clientName: Yup.string().required('Imię i nazwisko są wymagane'),
    address: Yup.string().required('Adres jest wymagany'),
    city: Yup.string().required('Miasto jest wymagane'),
    postalCode: Yup.string().required('Kod pocztowy jest wymagany'),
    country: Yup.string().required('Kraj jest wymagany'),
    nip: Yup.string().matches(/^[0-9]{10}$/, 'NIP musi składać się z 10 cyfr'),
    regon: Yup.string().matches(
      /^[0-9]{9}$/,
      'REGON musi składać się z 9 cyfr'
    ),
  });

  const onAddClient = async (body: IAddClientData) => {
    const dataToPost = {
      ...body,
      nip: body.nip === initialValues.nip ? null : body.nip,
      regon: body.regon === initialValues.regon ? null : body.regon,
    };

    const response = await Connector.postClient(dataToPost);
    refetchClients();
    onClose();
    const clientId = response?.data?.id;
    if (clientId) navigate(`${response?.data?.id}`);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onAddClient(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue, values, errors, touched }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Field
                name="companyName"
                as={TextField}
                label="Nazwa firmy"
                fullWidth
                error={touched.companyName && Boolean(errors.companyName)}
                helperText={<ErrorMessage name="companyName" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="clientName"
                as={TextField}
                label="Imię i nazwisko"
                fullWidth
                error={touched.clientName && Boolean(errors.clientName)}
                helperText={<ErrorMessage name="clientName" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="address"
                as={TextField}
                label="Ulica"
                fullWidth
                error={touched.address && Boolean(errors.address)}
                helperText={<ErrorMessage name="address" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="city"
                as={TextField}
                label="Miasto"
                fullWidth
                error={touched.city && Boolean(errors.city)}
                helperText={<ErrorMessage name="city" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="postalCode"
                as={TextField}
                label="Kod pocztowy"
                fullWidth
                error={touched.postalCode && Boolean(errors.postalCode)}
                helperText={<ErrorMessage name="postalCode" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="country"
                as={TextField}
                label="Kraj"
                fullWidth
                error={touched.country && Boolean(errors.country)}
                helperText={<ErrorMessage name="country" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="nip"
                as={TextField}
                label="NIP"
                fullWidth
                error={touched.nip && Boolean(errors.nip)}
                helperText={<ErrorMessage name="nip" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="regon"
                as={TextField}
                label="REGON"
                fullWidth
                error={touched.regon && Boolean(errors.regon)}
                helperText={<ErrorMessage name="regon" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isVat}
                    onChange={() => setFieldValue('isVat', !values.isVat)}
                    color="primary"
                  />
                }
                label="VAT"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isSignedContract}
                    onChange={() =>
                      setFieldValue(
                        'isSignedContract',
                        !values.isSignedContract
                      )
                    }
                    color="primary"
                  />
                }
                label="Kontrakt"
              />
            </Grid>
          </Grid>
          <div className="button-container">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
            >
              Zapisz
            </Button>
            <WarningButton onClick={onClose}>Anuluj</WarningButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddClient;
