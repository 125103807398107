import React from 'react';
import { useFormikContext } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import TextInput from 'components/CustomeMaterial/TextInput';
import './InvoiceItemsTable.scss';
import SuccessButton from 'components/CustomeMaterial/SuccessButton';
import Dropdown from 'components/CustomeMaterial/Dropdown';
import useConfig from 'hooks/useConfig';
import {
  getNettoValue,
  getBruttoValue,
  getSummaryNetto,
  getSummaryBrutto,
} from 'helpers/cashUtils';

const InvoiceItemsTable = () => {
  const { PRODUCT_TYPES, VAT_VALUES, GLOBAL_CONFIG: GC } = useConfig();
  const { handleChange, values, setFieldValue } = useFormikContext();

  const handleDeleteItem = index => {
    const updatedItems = [...values.invoiceItems];
    updatedItems.splice(index, 1);
    handleChange({ target: { name: 'invoiceItems', value: updatedItems } });
  };

  const addEmptyItem = () => {
    const updatedItems = [
      ...values.invoiceItems,
      {
        id: values.invoiceItems.length + 1,
        name: '',
        amount: '',
        nettoUnitPrice: '',
        bruttoUnitPrice: '',
        vatValue: values.invoiceType !== GC.INVOICE_TYPES.INVOICE ? 23 : 5, // newVat
        archive: 0,
      },
    ];
    handleChange({ target: { name: 'invoiceItems', value: updatedItems } });
  };

  const productsOptions = object =>
    Object.entries(object).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const priceRefresh = payload => {
    if (values.location === undefined) {
      alert('Wybierz lokalizację');
      return;
    }
    if (values.location.priceConfig === undefined) {
      alert('Ten produkty nie mają przypisanej ceny');
      return;
    }

    const priceConfig = JSON.parse(values.location.priceConfig);
    const price = priceConfig[payload.value];

    setFieldValue(`invoiceItems[${payload.indexValue}].nettoUnitPrice`, price);
  };

  return (
    <div className="invoice-items-table">
      <div className="invoice-item">
        <h3>Prodykty</h3>
        <table>
          {values.invoiceItems.map((item, index) => (
            <tr key={index}>
              <td>
                <Dropdown
                  name={`invoiceItems[${index}].name`}
                  id="name"
                  indexValue={index}
                  label="Nazwa"
                  options={productsOptions(PRODUCT_TYPES)}
                  onSelect={priceRefresh}
                />
              </td>
              <td>
                <TextInput
                  name={`invoiceItems[${index}].amount`}
                  placeholder="Ilość"
                  type="number"
                  value={item.amount}
                  onChange={handleChange}
                />
              </td>
              <td>
                <TextInput
                  name={`invoiceItems[${index}].nettoUnitPrice`}
                  placeholder="Cena netto"
                  type="number"
                  value={item.nettoUnitPrice}
                  onChange={handleChange}
                />
              </td>

              <td>
                <Dropdown
                  name={`invoiceItems[${index}].vatValue`}
                  id="vatValue"
                  label="VAT "
                  options={productsOptions(VAT_VALUES)}
                />
              </td>
              <td>
                <p>
                  Suma netto:
                  <br />
                  <strong>{getNettoValue(item)}</strong>zł
                </p>
              </td>
              <td>
                Suma brutto:
                <br />
                <strong>{getBruttoValue(item)}</strong> zł
              </td>
              <td>
                <FaTrashAlt
                  className="remove-icon"
                  onClick={() => handleDeleteItem(index)}
                />
              </td>
            </tr>
          ))}
        </table>
        <SuccessButton onClick={() => addEmptyItem()}>Dodaj pole</SuccessButton>
      </div>
      <div className="bottom-bar">
        <div className="summary">
          <p>
            Wartość netto:{' '}
            <strong>{getSummaryNetto(values.invoiceItems)} PLN</strong>
          </p>
          <p>
            Wartość brutto:{' '}
            <strong>{getSummaryBrutto(values.invoiceItems)} PLN</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceItemsTable;
