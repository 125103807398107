import { replaceDocumentIdentifier } from 'helpers/replaceDocumentIdentifier';

export const prepareInvoiceNumber = ({
  invoiceType,
  clientInvoiceNumber,
  createDate,
  number,
  numberFormat,
}) => {
  const {
    SELF_INVOICE_FORMATS,
    INVOICE_FORMATS,
    PROFORMA_FORMATS,
    INVOICE_CORRECTION_FORMATS,
    GLOBAL_CONFIG: GC,
  } = window.frontConfig;

  if (invoiceType == GC.INVOICE_TYPES.CLIENT_INVOICE) {
    return clientInvoiceNumber;
  }

  let currentInvoiceFormat = '';

  switch (Number(invoiceType)) {
    case GC.INVOICE_TYPES.SELF_INVOICE:
      currentInvoiceFormat = SELF_INVOICE_FORMATS;
      break;
    case GC.INVOICE_TYPES.PROFORMA:
      currentInvoiceFormat = PROFORMA_FORMATS;
      break;
    case GC.INVOICE_TYPES.CORRECTION:
      currentInvoiceFormat = INVOICE_CORRECTION_FORMATS;
      break;
    default:
      currentInvoiceFormat = INVOICE_FORMATS;
      break;
  }

  return replaceDocumentIdentifier({
    date: createDate,
    number: number,
    format: currentInvoiceFormat[numberFormat],
  });
};
