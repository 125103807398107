import React, { useRef, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Panel from './pages';
import useAuth from './hooks/useAuth';

const Root = () => {
  const timeoutIdRef = useRef<number>(null);

  const { logout } = useAuth();

  const resetTimer = () => {
    clearTimeout(timeoutIdRef.current as number);
    // @ts-ignore
    timeoutIdRef.current = setTimeout(logout, 1000 * 60 * 10); // 10 minut = 600000 ms
  };

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    resetTimer();

    return () => {
      clearTimeout(timeoutIdRef.current as number);
      window.removeEventListener('mousemove', resetTimer);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/*" element={<Panel />} />
      </Routes>
    </>
  );
};

export default Root;
