import { DeliveryContext } from 'providers/DeliveryProvider';

import { useContext } from 'react';

const useConfig = () => {
  const context = useContext(DeliveryContext);
  if (context === undefined) {
    throw new Error('useDelivery must be used within an DeliveryProvider');
  }

  return context;
};

export default useConfig;
