import { useState, useEffect } from 'react';
import moment from 'moment';
import useConfig from 'hooks/useConfig';
import ProductEditorPopup from './ProductsEditorPopup/ProductsEditorPopup';
import Connector from 'connector';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';
import AdmissionToMagazine from './AdmissionToMagazine/AdmissionToMagazine';
import { replaceDocumentIdentifier } from 'helpers/replaceDocumentIdentifier';
import { useNavigate } from 'react-router-dom';
import Popup2 from 'modules/Popup2/Popup2';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import {
  DangerButton,
  DateInput,
  Dropdown,
  SuccessButton,
  WarningButton,
} from 'components/CustomeMaterial';
import './Warehouses.scss';

const Warehouses = () => {
  const { UNITS, WASTE_DOCUMENT_NUMBER_FORMATS } = useConfig();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 0,
  });
  const [history, setHistory] = useState([]);
  const [products, setProducts] = useState(null);
  const [formikKey, setFormikKey] = useState(0);

  const fetchData = params => {
    Connector.getInventoryHistory(params).then(response => {
      setHistory(response.data.data);
      setPagination(response.data.pagination);

      Connector.getProducts().then(response => {
        setProducts(response.data);
      });
    });
  };

  useEffect(
    () =>
      fetchData({
        page,
        ...filters,
      }),
    [page, filters]
  );

  if (products === null) {
    return <div>Loading...</div>;
  }

  const getDocumentButton = row => {
    if (row?.description?.length > 0) {
      return row.description;
    } else if (row?.wasteCard?.id) {
      return (
        <DangerButton
          onClick={() => navigate(`/waste-cards/${row.wasteCard.id}`)}
        >
          {replaceDocumentIdentifier({
            date: row.wasteCard.transferDate,
            number: row.wasteCard.wasteNumber,
            format:
              WASTE_DOCUMENT_NUMBER_FORMATS[Number(row.wasteCard.driverId)],
          })}
        </DangerButton>
      );
    } else if (row?.invoice?.id) {
      return (
        <SuccessButton
          onClick={() =>
            navigate(`/invoices/${row.invoice.invoiceType}/${row.invoice.id}`)
          }
        >
          {replaceDocumentIdentifier({
            date: row.invoice.issueDate,
            number: row.invoice.number,
            format:
              row.invoice.invoiceType === 1
                ? 'FV NUM/MM/RRRR'
                : 'FV RRRR/MM/NUM',
          })}
        </SuccessButton>
      );
    } else if (row?.supplier?.id) {
      return (
        <div className="marker-info">
          Dostawca: <strong>{row.supplier.name}</strong>
        </div>
      );
    }

    return '';
  };

  const getProductsSummary = () => {
    return (
      <div className="Availability-module">
        {products.map((product, index) => (
          <div className="item" key={index}>
            {product.name} <br />{' '}
            <span>
              {product.count} {UNITS[product.unitId]}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="Warehouses-page card">
      <h4 className="page-title">Stany magazynowe</h4>

      <div className="button-container">
        <Popup2
          openButtonText="Ustawienia produktów"
          title="Ustawienia"
          component={ProductEditorPopup}
        />
        <Popup2
          openButtonText="Przyjmij/wydaj towar"
          title="Przyjęcie/wydanie towaru"
          component={AdmissionToMagazine}
          componentProps={{ onRefetch: fetchData }}
        />
      </div>

      {getProductsSummary()}

      <div className="InventoryHistory">
        <div key={formikKey}>
          <FormikWrapper
            key={formikKey}
            className="table-filter"
            initialValues={{}}
            onSubmit={params => {
              setFilters(params);
            }}
          >
            <div className="inputs-container">
              <DateInput id="dateFrom" placeholder="Data od" />
              <DateInput id="dateTo" placeholder="Data do" />
              <Dropdown
                id="productId"
                label="Produkt"
                options={products.map(item => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </div>
            <div className="button-container">
              <SuccessButton type="submit">Filtruj</SuccessButton>
              <WarningButton
                onClick={() => {
                  fetchData();
                  setFormikKey(formikKey + 1);
                }}
              >
                Resetuj
              </WarningButton>
            </div>
          </FormikWrapper>
        </div>
        <AdvancedTableWrapper
          data={history}
          config={{
            columns: [
              {
                id: 'product.name',
                label: 'Produkt',
                onRender: row => row.product.name,
              },
              {
                id: 'count',
                label: 'Ilość',
                onRender: row => `${row.count} ${UNITS[row.product.unitId]}`,
              },
              {
                id: 'changeDate',
                label: 'Data',
                onRender: row => moment(row.changeDate).format('DD-MM-YYYY'),
              },
              {
                id: 'type',
                label: 'Typ',
                onRender: row =>
                  row.changeType === 1 ? (
                    <div class="highlight success">Przyjęcie</div>
                  ) : (
                    <div className="highlight danger">Wydanie</div>
                  ),
              },
              {
                id: 'other',
                label: 'Inne',
                onRender: row => getDocumentButton(row),
              },
              {
                id: 'edit',
                label: 'Edytuj',
                onRender: row => (
                  <Popup2
                    openButtonText="Edytuj"
                    title="Edytuj"
                    component={AdmissionToMagazine}
                    componentProps={{
                      id: row.id,
                      onRefetch: fetchData,
                    }}
                  />
                ),
              },
              {
                id: 'delete',
                label: 'Usuń',
                onRender: row => (
                  <DangerButton
                    onClick={() => {
                      Connector.deleteInventoryHistory(row.id).then(() => {
                        fetchData();
                      });
                    }}
                  >
                    Usuń
                  </DangerButton>
                ),
              },
            ],
            pagination: {
              ...pagination,
              setPage,
            },
          }}
        />
      </div>
    </div>
  );
};

export default Warehouses;
