import { menuItems } from './config';
import { NavLink } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import useAuth from '../../hooks/useAuth';

import './Menu.scss';

const Menu = () => {
  const { logout } = useAuth();

  return (
    <div className='menu-page'>
      <div className='menu-wrapper'>
        <img
          src='/images/fehu-logo.png'
          alt='fehu investments'
        />
        <div className='menu-list'>
          {menuItems.map(item => (
            <NavLink key={item.value} to={item.href}>
              {item.icon && <item.icon className='menu-icon' />}
              <span className='menu-item-content'>{item.value}</span>
            </NavLink>
          ))}
          <div className='logout' onClick={logout}>
            <BiLogOut className='menu-icon'/> <span className='menu-item-content'>Wyloguj</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Menu;
