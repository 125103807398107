import React, { useState } from 'react';
import {
  Dropdown,
  SuccessButton,
  TextInput,
  WarningButton,
} from 'components/CustomeMaterial';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import useConfig from 'hooks/useConfig';
import Popup from 'modules/Popup/Popup';
import Connector from 'connector';

import './ProductsEditorPopup.scss';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';

const ProductEditorPopup = ({ onClose }) => {
  const { PRODUCTS, UNITS } = useConfig();
  const [editableProducts, setEditableProducts] = useState(
    PRODUCTS.filter(p => !p.archive)
  );
  const [archivedProducts, setArchivedProducts] = useState([]);

  const unitsOptions = () =>
    Object.entries(UNITS).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const fetchProducts = async () => {
    const products = await Connector.getProducts();
    setEditableProducts(products.data);
  };

  const addNewProduct = async data => {
    const newProduct = {
      id: undefined,
      name: data.name,
      specificName: data.specificName,
      unitId: data.unitId,
      archive: 0,
    };

    await Connector.postProduct(newProduct);
    window.location.reload();
  };

  const toArchive = async row => {
    await Connector.deleteProduct(row.id);
    window.location.reload();
  };

  return (
    <>
      <AdvancedTableWrapper
        config={{
          columns: [
            { id: 'name', label: 'Nazwa' },
            { id: 'specificName', label: 'Nazwa techniczna' },
            {
              id: 'unitId',
              label: 'Jednostka',
              onRender: row => UNITS[row.unitId],
            },
            {
              id: 'archive',
              label: 'Archiwizuj',
              onRender: row => (
                <WarningButton onClick={() => toArchive(row)}>
                  Archiwizuj
                </WarningButton>
              ),
            },
          ],
        }}
        data={editableProducts}
      />
      <FormikWrapper
        initialValues={{ name: '', specificName: '', unitId: 1 }}
        onSubmit={data => addNewProduct(data)}
        className="position"
      >
        <TextInput id="name" placeholder="Nazwa produktu" />
        <TextInput id="specificName" placeholder="Specyficzna nazwa produktu" />
        <Dropdown
          className="unit-dropdown"
          id={`unitId`}
          label="Jednostka"
          options={unitsOptions()}
        />

        <div className="button-container">
          <SuccessButton type="submit">Dodaj</SuccessButton>
          <WarningButton onClick={onClose}>Anuluj</WarningButton>
        </div>
      </FormikWrapper>
    </>
  );
};

export default ProductEditorPopup;
