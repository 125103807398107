import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import { TextField } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DateInput = ({ id, name, placeholder }) => {
  const formikContext = useFormikContext();

  const value = formikContext.values[name || id] || '0000-00-00T00:00:00.000Z';

  return (
    <div className="text-input-wrapper">
      <label>{placeholder}</label>
      <DatePicker
        value={moment(value)}
        onChange={date => {
          formikContext.setFieldValue(id, date.toISOString());
        }}
        format="DD/MM/YYYY"
        renderInput={props => <TextField {...props} />}
      />
      {formikContext.errors[id] && (
        <span className="validation-error-description">
          {formikContext.errors[id]}
        </span>
      )}
    </div>
  );
};

export default DateInput;
