import { useState } from 'react';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import TextInput from 'components/CustomeMaterial/TextInput';
import { SuccessButton, WarningButton } from 'components/CustomeMaterial';
import FetchListPickerInput from 'modules/FetchListPickerInput';

const DeliveriesSearchbar = ({ setFilters }) => {
  const initialValues = {};
  const [formKey, setFormKey] = useState(0);

  return (
    <div className="DelieversSearchbar" key={formKey}>
      <FormikWrapper
        initialValues={initialValues}
        onSubmit={values => setFilters(values)}
      >
        <div className="serchbar-inputs">
          <TextInput id="date" placeholder="Data" type="date" />
          <FetchListPickerInput name="driver" />
          <TextInput id="name" placeholder="Nazwa trasy" />
        </div>
        <div className="button-container">
          <SuccessButton type="submit">Szukaj</SuccessButton>
          <WarningButton
            onClick={() => {
              setFilters({});
              setFormKey(prev => prev + 1);
            }}
            type="reset"
          >
            Resetuj
          </WarningButton>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default DeliveriesSearchbar;
