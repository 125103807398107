import { Routes, Route } from 'react-router-dom';

import Clients from './Clients/Clients';
import Warehouses from './Warehouses/Warehouses';
import WasteCards from './WasteCards/WasteCards';
import SingleWasteCard from './WasteCards/SingleWasteCard/SingleWasteCard';
import Invoices from './Invoices/Invoices';
import Deliveries from './Deliveries/Deliveries';
import SingleClient from './Clients/SingleClient/SingleClient';
import Suppliers from './Suppliers/Suppliers';
import Menu from '../components/Menu/Menu';
import Settings from './Settings/Settings';
// import DeliveryTemplates from './DeliveryTemplates/DeliveryTemplates';
// import SingleDeliveryTemplate from './DeliveryTemplates/SingleDeliveryTemplate/SingleDeliveryTemplate';

import './styles.scss';
import SingleDelivery from './Deliveries/SingleDelivery/SingleDelivery';
import SingleInvoiceWrapper from './Invoices/SingleInvoice/SingleInvoiceWrapper';
import WasteCardsGenerator from './WasteCardsGenerator/WasteCardsGenerator';
import { DeliveryProvider } from 'providers/DeliveryProvider';

const Panel = () => {
  return (
    <div className="Panel-page">
      <div className="Panel-wrapper">
        <Menu />
        <div className="content-wrapper">
          <Routes>
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/:id" element={<SingleClient />} />
            <Route path="/deliveries" element={<Deliveries />} />
            <Route
              path="/deliveries/:id"
              element={
                <DeliveryProvider>
                  <SingleDelivery />
                </DeliveryProvider>
              }
            />
            <Route path="/warehouse" element={<Warehouses />} />
            <Route
              path="/wastecards-generator"
              element={<WasteCardsGenerator />}
            />
            <Route path="/waste-cards" element={<WasteCards />} />
            <Route path="/waste-cards/:id" element={<SingleWasteCard />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route
              path="/invoices/:invoiceType/:id"
              element={<SingleInvoiceWrapper />}
            />
            <Route path="/suppliers" element={<Suppliers />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Panel;
