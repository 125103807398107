import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  TextInput,
  DateInput,
  FormWarning,
  Dropdown,
  SuccessButton,
  PrimaryButton,
  DangerButton,
  PaidCheckbox,
  WarningButton,
  TextareaInput,
} from 'components/CustomeMaterial';

import FetchListPickerInput from 'modules/FetchListPickerInput';
import { prepareInvoiceNumber } from 'helpers/invoices.helpers';

import InvoiceItemsTable from './InvoiceItemsTable/InvoiceItemsTable';
import InvoiceItemsCorrectionTable from './InvoiceItemsTable/InvoiceItemsCorrectionTable';
import { useFormikContext } from 'formik';
import { map } from 'lodash';

import Connector from 'connector';

import useConfig from 'hooks/useConfig';
import moment from 'moment';

const SingleInvoiceFormContent = ({ invoiceType, isNew }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isCorrectFor = location?.state?.invoiceToCorrectedId;

  const {
    PAY_TYPES,
    PROFORMA_FORMATS,
    INVOICE_FORMATS,
    INVOICE_CORRECTION_FORMATS,
    SELF_INVOICE_FORMATS,
    GLOBAL_CONFIG: GC,
    DICTIONARIES: DC,
  } = useConfig();
  const { setFieldValue, values, handleChange } = useFormikContext();

  const fetchLastInvoiceNumber = async () => {
    const payload = await Connector.getLastInvoiceNumber({
      invoiceType,
      month: moment().month() + 1,
      year: moment().year(),
      format: values.numberFormat,
    });

    setFieldValue('number', Number(payload.data.data.number) + 1);
  };

  useEffect(() => {
    if (isNew && invoiceType != GC.INVOICE_TYPES.CLIENT_INVOICE) {
      fetchLastInvoiceNumber();
    }
  }, [values.numberFormat, id]);

  useEffect(() => {
    if (invoiceType == GC.INVOICE_TYPES.CORRECTION) {
      if (isNew) {
        setFieldValue('id', undefined);
        setFieldValue('createDate', moment().format('YYYY-MM-DD'));
      }
      if (isCorrectFor) {
        setFieldValue('isCorrectFor', isCorrectFor);
      }
      setFieldValue('invoiceType', GC.INVOICE_TYPES.CORRECTION);
    }
  }, [invoiceType]);

  const getClientData = () =>
    [
      { key: 'companyName', name: 'Nazwa' },
      { key: 'nip', name: 'NIP' },
      { key: 'clientName', name: 'Klient' },
      { key: 'city', name: 'Miasto' },
      { key: 'address', name: 'Adres' },
    ].map(({ key, name }) => (
      <p key={key}>
        <b>{name}: </b>
        {values.client?.[key]}
      </p>
    ));

  const printPDF = async () => {
    const response = await Connector.getInvoicePDF(id);
    const pdfBlob = response.data;
    const url = window.URL.createObjectURL(
      new Blob([pdfBlob], { type: 'application/pdf' })
    );
    const pdfWindow = window.open();
    pdfWindow.location.href = url;
  };

  const getTitleInvoice = () =>
    `${isNew ? 'Nowa ' : ''} ${
      DC.INVOICE_TYPES[invoiceType]
    } - ${prepareInvoiceNumber({ ...values })}`;

  let currentInvoiceFormat = '';

  switch (Number(invoiceType)) {
    case GC.INVOICE_TYPES.SELF_INVOICE:
      currentInvoiceFormat = SELF_INVOICE_FORMATS;
      break;
    case GC.INVOICE_TYPES.PROFORMA:
      currentInvoiceFormat = PROFORMA_FORMATS;
      break;
    case GC.INVOICE_TYPES.CORRECTION:
      currentInvoiceFormat = INVOICE_CORRECTION_FORMATS;
      break;
    default:
      currentInvoiceFormat = INVOICE_FORMATS;
      break;
  }

  return (
    <>
      {invoiceType == GC.INVOICE_TYPES.CLIENT_INVOICE ? (
        <h3>{DC.INVOICE_TYPES[invoiceType]}</h3>
      ) : (
        <div className="InvoiceTitle">
          <h2>{getTitleInvoice()}</h2>
        </div>
      )}
      {values?.proformaId && !isNew && (
        <PrimaryButton
          onClick={() => {
            window.location.href = `/invoices/${GC.INVOICE_TYPES.PROFORMA}/${values.proformaId}`;
          }}
        >
          Przejdź do Proformy
        </PrimaryButton>
      )}
      {invoiceType == GC.INVOICE_TYPES.PROFORMA &&
        !values?.createdByProforma &&
        !isNew && (
          <PrimaryButton
            onClick={() => {
              setFieldValue('invoiceType', GC.INVOICE_TYPES.INVOICE);
              setFieldValue('id', undefined);
              setFieldValue('proformaId', id);
              navigate(`/invoices/${GC.INVOICE_TYPES.INVOICE}/new`);
            }}
          >
            Wystaw fakturę
          </PrimaryButton>
        )}

      {invoiceType == GC.INVOICE_TYPES.PROFORMA &&
        values?.createdByProforma && (
          <PrimaryButton
            onClick={() => {
              window.location.href = `/invoices/${GC.INVOICE_TYPES.INVOICE}/${values?.createdByProforma?.id}`;
            }}
          >
            Przejdź do faktury
          </PrimaryButton>
        )}

      {invoiceType == GC.INVOICE_TYPES.CORRECTION && values.corrected && (
        <PrimaryButton
          onClick={() => {
            const { invoiceType, id } = values.corrected;
            window.location.href = `/invoices/${invoiceType}/${id}`;
          }}
        >
          Przejdź do oryginalnej faktury
        </PrimaryButton>
      )}
      <Grid container>
        <Grid item xs={12} md={3} className="client-data">
          <div className="client-description">
            <b>Dane klienta:</b>
            {getClientData()}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          {invoiceType == GC.INVOICE_TYPES.CLIENT_INVOICE ? (
            <TextInput id="clientInvoiceNumber" placeholder="Numer faktury" />
          ) : (
            <>
              <Dropdown
                id="numberFormat"
                label="Format numeru"
                options={map(currentInvoiceFormat, (item, key) => ({
                  value: key,
                  label: item,
                }))}
              />
              <TextInput id="number" placeholder="Numer faktury" />
            </>
          )}

          <FetchListPickerInput
            name="location"
            postFetchSelected={({ formikInstance, response }) => {
              formikInstance.setFieldValue('client', response?.clients);
              formikInstance.setFieldValue('clientId', response?.clients?.id);
              formikInstance.setFieldValue('location', response);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextInput id="placeOfCreate" placeholder="Miejsce wystawienia" />
          <DateInput id="createDate" placeholder="Data wystawienia" />
          <DateInput id="executionDate" placeholder="Data wykonania" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            id="payType"
            label="Typ płatności"
            options={map(PAY_TYPES, (item, index) => ({
              value: index,
              label: item,
            }))}
          />
          <DateInput id="payTerm" placeholder="Termin płatności" />
          {values.payType == 2 ? (
            <PaidCheckbox id="paidDate" placeholder="Opłacona" />
          ) : (
            <>
              <DateInput id="paidDate" placeholder="Data opłacenia" />
              <FormWarning
                id="paidDate"
                message="Faktura nieopłacona"
                validation={value => {
                  return value == null;
                }}
              />
            </>
          )}
        </Grid>
      </Grid>

      <div className="invoice-items">
        <TextareaInput id="comments" placeholder="Uwagi" />
        {invoiceType == GC.INVOICE_TYPES.CORRECTION ? (
          <InvoiceItemsCorrectionTable />
        ) : (
          <InvoiceItemsTable />
        )}
        {invoiceType == GC.INVOICE_TYPES.INVOICE && values.correctionInvoice ? (
          <div className="bottom-bar">
            <PrimaryButton
              onClick={() => {
                window.location.href = `/invoices/${values.correctionInvoice.invoiceType}/${values.correctionInvoice.id}`;
              }}
            >
              Przejdź do korekty
            </PrimaryButton>
          </div>
        ) : (
          <div className="bottom-bar">
            <div className="summary">
              <SuccessButton type="submit">Zapisz</SuccessButton>
              {!isNew && (
                <>
                  <PrimaryButton onClick={printPDF}>
                    Drukuj / Generuj PDF
                  </PrimaryButton>

                  <DangerButton
                    onClick={async () => {
                      await Connector.deleteInvoice(id);
                      navigate('/invoices');
                    }}
                  >
                    Usuń
                  </DangerButton>
                  {invoiceType != GC.INVOICE_TYPES.PROFORMA &&
                    invoiceType != GC.INVOICE_TYPES.CORRECTION && (
                      <WarningButton
                        onClick={() => {
                          navigate(`/invoices/5/new`, {
                            state: {
                              invoiceToCorrectedId: Number(id),
                            },
                          });
                        }}
                      >
                        Wystaw korektę
                      </WarningButton>
                    )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleInvoiceFormContent;
