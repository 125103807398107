import { useFormikContext } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import { TextInput, TextareaInput } from 'components/CustomeMaterial';
import './InvoiceItemsTable.scss';
import Dropdown from 'components/CustomeMaterial/Dropdown';
import useConfig from 'hooks/useConfig';
import {
  getNettoValue,
  getBruttoValue,
  getSummaryNetto,
  getSummaryBrutto,
} from 'helpers/cashUtils';

const InvoiceItemsCorrectionTable = () => {
  const { PRODUCT_TYPES, VAT_VALUES, GLOBAL_CONFIG: GC } = useConfig();
  const { handleChange, values, setFieldValue } = useFormikContext();

  if (
    values?.invoiceItems?.length &&
    values?.correctionInvoiceItems?.length === 0
  ) {
    const correctionInvoiceItems = values.invoiceItems.map(item => ({
      ...item,
      isCorrectFor: item.id,
      id: undefined,
    }));
    setFieldValue('correctionInvoiceItems', correctionInvoiceItems);
  }

  const productsOptions = object =>
    Object.entries(object).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const priceRefresh = payload => {
    if (values.location === undefined) {
      alert('Wybierz lokalizację');
      return;
    }
    if (values.location.priceConfig === undefined) {
      alert('Ten produkty nie mają przypisanej ceny');
      return;
    }

    const priceConfig = JSON.parse(values.location.priceConfig);
    const price = priceConfig[payload.value];

    setFieldValue(
      `correctionInvoiceItems[${payload.indexValue}].nettoUnitPrice`,
      price
    );
  };

  const isChangedClass = (original, corrected) => {
    return original != corrected ? 'changed' : '';
  };
  return (
    <div className="invoice-items-table">
      <div className="invoice-item">
        <h3>Prodykty</h3>
        <table>
          {values.correctionInvoiceItems.map((item, index) => (
            <tr key={index}>
              <td>
                <p
                  className={`original ${isChangedClass(
                    values.invoiceItems[index].product.name,
                    item.product.name
                  )}`}
                >
                  {values.invoiceItems[index].product.name}
                </p>
                <Dropdown
                  name={`correctionInvoiceItems[${index}].name`}
                  id="name"
                  disabled={true}
                  indexValue={index}
                  label="Nazwa"
                  options={productsOptions(PRODUCT_TYPES)}
                  onSelect={priceRefresh}
                />
              </td>
              <td>
                <p
                  className={`original ${isChangedClass(
                    values.invoiceItems[index].amount,
                    item.amount
                  )}`}
                >
                  {values.invoiceItems[index].amount}
                </p>
                <TextInput
                  name={`correctionInvoiceItems[${index}].amount`}
                  placeholder="Ilość"
                  type="number"
                  value={item.amount}
                  onChange={handleChange}
                />
              </td>
              <td>
                <p
                  className={`original ${isChangedClass(
                    values.invoiceItems[index].nettoUnitPrice,
                    item.nettoUnitPrice
                  )}`}
                >
                  {values.invoiceItems[index].nettoUnitPrice}
                </p>
                <TextInput
                  name={`correctionInvoiceItems[${index}].nettoUnitPrice`}
                  placeholder="Cena netto"
                  type="number"
                  value={item.nettoUnitPrice}
                  onChange={handleChange}
                />
              </td>

              <td>
                <p
                  className={`original ${isChangedClass(
                    values.invoiceItems[index].vatValue,
                    item.vatValue
                  )}`}
                >
                  {values.invoiceItems[index].vatValue}
                </p>
                <Dropdown
                  name={`correctionInvoiceItems[${index}].vatValue`}
                  id="vatValue"
                  label="VAT"
                  options={productsOptions(VAT_VALUES)}
                />
              </td>
              <td>
                <p className="original">
                  {getNettoValue(values.invoiceItems[index])} zł
                </p>
                <p className="cash-indicator">
                  Suma netto:
                  <br />
                  <strong>{getNettoValue(item)} zł</strong>
                </p>
              </td>
              <td>
                <p className="original">
                  {getBruttoValue(values.invoiceItems[index])} zł
                </p>
                <p className="cash-indicator">
                  Suma brutto:
                  <br />
                  <strong>{getBruttoValue(item)} zł</strong>
                </p>
              </td>
            </tr>
          ))}
        </table>
        {/* <SuccessButton onClick={() => addEmptyItem()}>Dodaj pole</SuccessButton> */}
      </div>
      <TextareaInput id="reasonOfCorrection" placeholder="Powód korekty" />

      <div className="bottom-bar">
        <div className="summary">
          <h5>Przed korektą:</h5>
          <p>
            Wartość netto:{' '}
            <strong>{getSummaryNetto(values.invoiceItems)} PLN</strong>
          </p>
          <p>
            Wartość brutto:{' '}
            <strong>{getSummaryBrutto(values.invoiceItems)} PLN</strong>
          </p>
        </div>
        <div className="summary">
          <h5>Po korekcie:</h5>
          <p>
            Wartość netto:{' '}
            <strong>
              {getSummaryNetto(values.correctionInvoiceItems)} PLN
            </strong>
          </p>
          <p>
            Wartość brutto:{' '}
            <strong>
              {getSummaryBrutto(values.correctionInvoiceItems)} PLN
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceItemsCorrectionTable;
