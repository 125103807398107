import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import Connector from 'connector';
import FetchListPickerInput from 'modules/FetchListPickerInput/FetchListPickerInput';
import {
  DateInput,
  SuccessButton,
  WarningButton,
  TextInput,
  CheckboxInput,
  DangerButton,
  GoByParamInputButton,
  PrimaryButton,
} from 'components/CustomeMaterial';

export const SingleInvoiceFormContent = ({ isNew }) => {
  const { setFieldValue, values } = useFormikContext();
  const navigate = useNavigate();
  const [locationKeyIteration, setLocationKeyIteration] = useState(0);
  const { id } = useParams();

  const fetchLastNumber = async () => {
    const response = await Connector.getWasteCardsNewNumber({
      transferDate: values.transferDate,
      driverId: values.driverId,
    });
    setFieldValue('wasteNumber', Number(response?.data?.data));
  };

  useEffect(() => {
    if (isNew && values.driverId && values.transferDate) {
      fetchLastNumber();
    }
  }, [values.driverId, values.transferDate]);

  const onRemoveHandler = async () => {
    await Connector.deleteWasteCard(id);
    navigate('/waste-cards');
  };

  const onPrintWasteCard = async type => {
    let response = null;
    if (type === 'A4') {
      response = await Connector.getWasteCardA4PDF(id);
    } else {
      response = await Connector.getWasteCardSmallPDF(id);
    }
    const pdfBlob = response.data;
    const url = window.URL.createObjectURL(
      new Blob([pdfBlob], { type: 'application/pdf' })
    );
    const pdfWindow = window.open();
    pdfWindow.location.href = url;
  };

  return (
    <>
      <div className="inputs-container">
        <TextInput id="wasteNumber" placeholder="Numer (automatyczny)" />
        <FetchListPickerInput
          name="client"
          postFetchSelected={() => {
            if (locationKeyIteration > 0) {
              setFieldValue('locationId', null);
            }
            setLocationKeyIteration(prev => prev + 1);
          }}
        />
        <div key={`${locationKeyIteration}-${values.clientId}`}>
          <FetchListPickerInput
            key={values.clientId}
            name="location"
            expandConfig={{
              filters: [
                {
                  initialValue: values?.clientId || undefined,
                  id: 'clientId',
                  placeholder: 'Klient Id',
                  isForced: true,
                  isHidden: true,
                },
              ],
            }}
          />
        </div>
        <FetchListPickerInput name="driver" />
        <DateInput id="transferDate" placeholder="Data transferu" />
        <TextInput id="count" placeholder="Ilość" />
        <CheckboxInput id="noInvoice" placeholder="Bezfakturowa" />
        <FetchListPickerInput name="invoice" />
        <GoByParamInputButton
          id="invoice"
          hideCondition={values => !values.invoiceId}
          onClick={value =>
            navigate(`/invoices/Samofaktura/${value.invoiceId}`)
          }
        >
          Przejdź do faktury
        </GoByParamInputButton>
      </div>
      <div className="button-container">
        <SuccessButton type="submit">Zapisz</SuccessButton>
        <WarningButton onClick={() => navigate('/waste-cards')}>
          Anuluj
        </WarningButton>
        {id !== 'new' && (
          <>
            <DangerButton onClick={onRemoveHandler}>Usuń</DangerButton>
            <PrimaryButton onClick={() => onPrintWasteCard('A4')}>
              Drukuj A4
            </PrimaryButton>
            <PrimaryButton onClick={() => onPrintWasteCard()}>
              Drukuj Mini
            </PrimaryButton>
          </>
        )}
      </div>
    </>
  );
};

export default SingleInvoiceFormContent;
