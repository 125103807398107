import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import './Settings.scss';
import Connector from '../../connector';

const Settings = () => {
  // Inicjalizujemy stan na podstawie window.frontConfig.SETTINGS
  const [settings, setSettings] = useState(window.frontConfig.SETTINGS || {});

  // Aktualizacja wartości w stanie
  const handleInputChange = (key, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  // Zapis ustawienia do backendu
  const handleSave = async ({ newValue, key }) => {
    await Connector.saveSetting({ key, value: newValue });
    window.frontConfig.SETTINGS[key].value = newValue;
  };

  return (
    <div className="Settings-page card">
      <h4 className="page-title">Ustawienia</h4>
      <Stack spacing={2}>
        {console.log(Object.keys(settings))}
        {Object.keys(settings).map(keyId => {
          const { key, value, description } = settings[keyId];
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSave({ newValue: e.target[0].value, key });
              }}
              key={key}
              className="setting-row"
            >
              <label htmlFor={key}>{description}</label>
              <input id={key} type="text" defaultValue={value} />
              <Button type="submit">Zapisz</Button>
            </form>
          );
        })}
      </Stack>
    </div>
  );
};

export default Settings;
