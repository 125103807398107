import React from 'react';
import { useFormikContext } from 'formik';
import './TextInput.scss';

const TextareaInput = ({ id, name, placeholder }) => {
  const formik = useFormikContext();

  const handleChange = event => {
    formik.setFieldValue(name || id, event.target.value);
  };

  return (
    <div className="text-input-wrapper">
      <label htmlFor={name || id}>{placeholder}</label>
      <textarea
        id={name || id}
        name={name || id}
        value={formik.values[name || id]}
        onChange={handleChange}
      />

      {formik.errors[name || id] && (
        <span className="validation-error-description">
          {formik.errors[name || id]}
        </span>
      )}
    </div>
  );
};

export default TextareaInput;
