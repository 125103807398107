import { useState } from 'react';
import {
  SuccessButton,
  WarningButton,
  DateInput,
} from 'components/CustomeMaterial';
import FormikWrapper from 'components/FormikWrapper';
import FetchListPickerInput from 'modules/FetchListPickerInput/FetchListPickerInput';

const SearchWasteCards = ({ setFilters }) => {
  const [formKey, setFormKey] = useState(0);

  return (
    <div className="SearchWasteCards-module table-filter" key={formKey}>
      <FormikWrapper onSubmit={setFilters}>
        <div className="inputs-container">
          <FetchListPickerInput name="client" />
          <FetchListPickerInput name="location" />
          <FetchListPickerInput name="driver" />
        </div>
        <div className="inputs-container">
          <DateInput id="startDate" placeholder="Początkowa data" />
          <DateInput id="endDate" placeholder="Końcowa data" />
        </div>

        <div className="button-container">
          <SuccessButton type="submit">Szukaj</SuccessButton>
          <WarningButton
            onClick={() => {
              setFilters({});
              setFormKey(formKey + 1);
            }}
          >
            Wyczyść
          </WarningButton>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default SearchWasteCards;
