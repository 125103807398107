import { Formik, Form } from 'formik';
import TextInput from 'components/CustomeMaterial/TextInput';

import { Button, Stack } from '@mui/material';

const ClientsSearchbar = ({ searchClients, clearFilters }) => {
  const initialValues = {
    companyName: '',
    clientName: '',
    nip: '',
    city: '',
    address: '',
    regon: '',
  };

  return (
    <div className="Clients-searchbar">
      <h4>Szukaj klientów</h4>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          const searchParams = {
            companyName: values.companyName || '',
            city: values.city || '',
            address: values.address || '',
            clientName: values.clientName || '',
            ...(values.nip && { nip: Number(values.nip) }),
            ...(values.regon && { regon: Number(values.regon) }),
          };

          searchClients(searchParams);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="searchbar">
              <TextInput id="companyName" placeholder="Firma" />
              <TextInput id="clientName" placeholder="Właściciel" />
              <TextInput id="nip" placeholder="NIP" type="number" />
              <TextInput id="regon" placeholder="REGON" type="number" />
              <TextInput id="city" placeholder="Miasto" />
              <TextInput id="address" placeholder="Ulica" />
            </div>
            <div className="search-btn">
              <Stack spacing={1} direction={'row'}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Szukaj
                </Button>
                <Button
                  type="reset"
                  variant="outlined"
                  color="warning"
                  onClick={clearFilters}
                >
                  Wyczyść
                </Button>
              </Stack>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClientsSearchbar;
